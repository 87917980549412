import React, { Fragment, ReactNode } from "react";
import { Menu, Transition } from "@headlessui/react";
import clsx from "clsx";
import { DotsVerticalIcon } from "@heroicons/react/outline";

type Item = React.ReactElement | string | null;
export interface ActionMenuProps {
  items: Item[];
  onSelect?: (index: number) => void | Promise<void>;
  direction?: string;
  className?: string;
  itemsClassName?: string;
  itemClassName?: string;
  containerClassName?: string;
  itemDisabled?: boolean[];
  icon?: ReactNode;
  leadingIcon?: ReactNode;
  children?: ReactNode;
  dotSize?: string | number;
  testID?: string;
}

const ActionMenu = ({
  items,
  direction = "bottom",
  onSelect,
  className,
  itemsClassName,
  itemClassName,
  containerClassName,
  itemDisabled,
  icon,
  children,
  dotSize = 14,
  testID,
  ...props
}: ActionMenuProps) => {
  return (
    <Menu as="div" className="relative">
      <Menu.Button
        as="div"
        className={clsx(
          "rounded relative flex justify-between items-center hover:text-gray-600 cursor-pointer",
          className
        )}
        test-id={testID}
      >
        {props?.leadingIcon ? (
          <span className="mr-3">{props.leadingIcon}</span>
        ) : null}
        {children}
        {icon ?? (
          <DotsVerticalIcon
            width={dotSize}
            className="text-gray-500 hover:text-black"
          />
        )}
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          as="div"
          className={clsx(
            `absolute right-0 z-10 mt-1 origin-top divide-y ${
              direction === "bottom" ? "top-5" : "bottom-6"
            }`,
            "divide-gray-300 rounded-md bg-white",
            "ring-black ring-opacity-5 focus:outline-none min-w-[150px]",
            itemsClassName,
            {
              "top-auto bottom-auto left-0": direction === "auto",
              "top-0 right-full h-max": direction === "bottom-left",
              "top-0 left-full h-max": direction === "bottom-right",
            }
          )}
        >
          <div
            className={clsx("py-1 border border-gray-300", containerClassName)}
          >
            {items
              .filter((item: Item) => item !== null)
              .map((item: Item, i: number) => {
                return (
                  <Menu.Item disabled={itemDisabled?.[i]} key={i}>
                    {({ active }) => (
                      <div
                        onClick={() => {
                          onSelect && onSelect(i);
                        }}
                        className={clsx(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "w-full text-left px-4 py-2 text-sm flex items-center",
                          itemDisabled?.[i]
                            ? "cursor-not-allowed opacity-50"
                            : "cursor-pointer",
                          itemClassName
                        )}
                        test-id={`${testID}_row_${i}`}
                      >
                        {item}
                      </div>
                    )}
                  </Menu.Item>
                );
              })}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default ActionMenu;
