import React from "react";
import Logo from "assets/images/circularLCA_logo-new.svg";
import OneLOGO from "assets/images/one_logo.svg";
import { useLocation, useNavigate } from "react-router";
import clsx from "clsx";
import { ChevronDoubleLeftIcon } from "@heroicons/react/outline";
import { IconLayoutDashboard } from "@tabler/icons-react";
import { IS_CONCULAR_ONE } from "utils";

const SidebarTop = ({ toggleSidebar }: { toggleSidebar: () => void }) => {
  const navigate = useNavigate();
  const location = useLocation();

  function dashboardLink() {
    return (
      <div
        className={clsx(
          "flex px-2 py-2 text-gray-700 text-sm font-medium items-center mt-[15px] mx-2 mb-2 cursor-pointer",
          { "bg-indigo-50 rounded": location.pathname === "/dashboard" }
        )}
        onClick={() => navigate("/dashboard")}
        test-id="dashboard_button"
      >
        <IconLayoutDashboard className="text-gray-500 mr-2" />
        Dashboard
      </div>
    );
  }

  return (
    <div className="mt-4 flex flex-col">
      <div
        className={clsx(
          "flex flex-shrink-0 items-center px-3 justify-between",
          { "pb-3": IS_CONCULAR_ONE }
        )}
      >
        <img
          className={clsx("h-5 w-auto cursor-pointer", {
            "h-6": IS_CONCULAR_ONE,
          })}
          src={IS_CONCULAR_ONE ? OneLOGO : Logo}
          alt="Concular"
          onClick={() => navigate("/")}
        />
        <ChevronDoubleLeftIcon
          width={18}
          className="cursor-pointer hover:text-black rounded-full text-gray-500"
          onClick={toggleSidebar}
        />
      </div>
      {!IS_CONCULAR_ONE && dashboardLink()}
    </div>
  );
};

export default SidebarTop;
