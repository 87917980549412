import { ShareIcon } from "@heroicons/react/outline";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import OrganisationMenu from "./Settings";
import { OrganisationOUT } from "api-client";
import OrganisationLogo from "./OrganisationLogo";
import Tooltip from "components/Tooltip";
import { organisationStore } from "store/OrganisationStore";
import { observer } from "mobx-react-lite";
import { Organisation } from "./CollapseOrganisationsList";
import OrganisationPending from "./OrganisationPending";
import { useNavigate } from "react-router";

const OrganisationsList = () => {
  const { t } = useTranslation();
  const { organisations } = organisationStore;
  const navigate = useNavigate();

  if (organisations.length === 0) return null;
  return (
    <div>
      <div className="p-3 mb-1 py-2 text-sm font-medium text-gray-600">
        {`${t("dashboard.yourOrganisations")} (${organisations.length})`}
      </div>
      {organisations.map((organisation: Organisation, index: number) => {
        return (
          <div
            key={organisation.id}
            className={clsx("p-3 text-sm last-of-type:border-b-0 py-2")}
          >
            <div className="flex justify-between relative cursor-default items-center">
              {organisation.status === "pending" ? (
                <OrganisationPending organisation={organisation} />
              ) : (
                <div
                  className="flex items-center w-full"
                  onClick={() => navigate("/one")}
                >
                  {organisation?.shared ? (
                    <div className="w-7 h-7 border rounded flex justify-center items-center">
                      <ShareIcon width={15} />
                    </div>
                  ) : (
                    <OrganisationLogo organisation={organisation} />
                  )}

                  <div className="pl-3 whitespace-nowrap font-semibold text-gray-700 transition hover:text-black">
                    <Tooltip truncate className="cursor-pointer max-w-[160px]">
                      {organisation.name}
                    </Tooltip>
                  </div>
                </div>
              )}
              {organisation.status !== "pending" && (
                <OrganisationMenu
                  index={index}
                  organisation={organisation as OrganisationOUT}
                />
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default observer(OrganisationsList);
