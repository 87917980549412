import { ShareIcon } from "@heroicons/react/outline";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import OrganisationMenu from "./Settings";
import { OrganisationOUT } from "api-client";
import BuildingsList from "features/Building/BuildingsList";
import Collapse from "components/Collapse";
import OrganisationLogo from "./OrganisationLogo";
import Tooltip from "components/Tooltip";
import { organisationStore } from "store/OrganisationStore";
import { observer } from "mobx-react-lite";
import {
  IconBuildingWarehouse,
  IconLayoutDashboard,
} from "@tabler/icons-react";
import { useLocation, useNavigate, useParams } from "react-router";
import useFeatureFlag from "hooks/useFeatureFlag";
import {
  ORG_ACCORDION_STATUS_KEY,
  PORTFOLIO_URL,
  URBAN_MINING_HUB_URL,
} from "utils";
import OrganisationPending from "./OrganisationPending";

export interface Organisation extends OrganisationOUT {
  organisation_type_id?: string;
  joinRequest?: boolean;
  status?: string;
  shared?: boolean;
}

interface AccordionStatus {
  id: string;
  status: boolean;
}

const CollapseOrganisationsList = () => {
  const { t } = useTranslation();
  const { organisations } = organisationStore;
  const [openOrganisation, setOpenOrganisation] = useState<
    AccordionStatus[] | []
  >([]);
  const navigate = useNavigate();
  const { isFeatureVisible } = useFeatureFlag();
  const { pathname } = useLocation();
  const { organisation_id } = useParams();

  const updateOpenOrganisation = (e: React.MouseEvent, index: number) => {
    e.stopPropagation();
    if (openOrganisation?.length) {
      const current = openOrganisation[index];
      current.status = !openOrganisation[index].status;
    }
    localStorage.setItem(
      ORG_ACCORDION_STATUS_KEY,
      JSON.stringify(openOrganisation)
    );
    setOpenOrganisation([...openOrganisation]);
  };

  useEffect(() => {
    if (!organisations.length) return;
    setAccordionsState();
  }, [organisations.length]);

  function setAccordionsState() {
    const currentStatus: AccordionStatus[] = organisations.map((item) => ({
      id: item.id,
      status: checkStatus(item.id),
    }));
    if (!localStorage.getItem(ORG_ACCORDION_STATUS_KEY))
      localStorage.setItem(
        ORG_ACCORDION_STATUS_KEY,
        JSON.stringify(currentStatus)
      );
    setOpenOrganisation(currentStatus);
  }

  function checkStatus(id: string) {
    const prevState = JSON.parse(
      localStorage.getItem(ORG_ACCORDION_STATUS_KEY) ?? "null"
    );

    if (!prevState?.length) {
      return organisations.length === 1;
    } else {
      return (
        prevState.find((item: { id: string }) => item.id === id)?.status ??
        false
      );
    }
  }

  function openPortfolio(organisation_id: string) {
    navigate(`../${organisation_id}/${PORTFOLIO_URL}`, { replace: true });
  }

  function openUrbanMiningHub(organisation_id: string) {
    navigate(`../${organisation_id}/${URBAN_MINING_HUB_URL}`, {
      replace: true,
    });
  }

  function retoolMenus(item_org_id: string) {
    const menuStyle =
      "flex cursor-pointer items-center py-2 px-1 mb-1 hover:bg-indigo-50 rounded";
    return (
      <>
        <div
          className={clsx(menuStyle, {
            "bg-indigo-50":
              pathname.includes(PORTFOLIO_URL) &&
              item_org_id === organisation_id,
          })}
          onClick={() => openPortfolio(item_org_id)}
        >
          <IconLayoutDashboard className="text-gray-500 mr-3" />
          Portfolio Dashboard
        </div>
        {isFeatureVisible("urban_mining_hub") ? (
          <div
            className={clsx(menuStyle, {
              "bg-indigo-50":
                pathname.includes(URBAN_MINING_HUB_URL) &&
                item_org_id === organisation_id,
            })}
            onClick={() => openUrbanMiningHub(item_org_id)}
          >
            <IconBuildingWarehouse className="text-gray-500 mr-3" />
            Urban Mining Hub
          </div>
        ) : null}
      </>
    );
  }

  if (organisations.length === 0) return null;
  return (
    <Collapse
      headerTitle={`${t("dashboard.yourOrganisations")} (${
        organisations.length
      })`}
      headerClassName="border-b"
    >
      {organisations.map((organisation: Organisation, index: number) => {
        return (
          <div
            key={organisation.id}
            className="p-3 text-sm border-b border-gray-200 last-of-type:border-b-0"
          >
            <div
              className={clsx(
                "flex justify-between relative cursor-default items-center"
              )}
            >
              {organisation.status === "pending" ? (
                <OrganisationPending organisation={organisation} />
              ) : (
                <div
                  className="flex items-center w-full"
                  onClick={(e) => updateOpenOrganisation(e, index)}
                >
                  {organisation?.shared ? (
                    <div className="w-7 h-7 border rounded flex justify-center items-center">
                      <ShareIcon width={15} />
                    </div>
                  ) : (
                    <OrganisationLogo organisation={organisation} />
                  )}

                  <div className="pl-3 whitespace-nowrap font-semibold text-gray-700 transition hover:text-black">
                    <Tooltip truncate className="cursor-pointer max-w-[160px]">
                      {organisation.name}
                    </Tooltip>
                  </div>
                </div>
              )}
              {organisation.status !== "pending" && (
                <OrganisationMenu
                  openOrganisation={openOrganisation}
                  updateOpenOrganisation={updateOpenOrganisation}
                  index={index}
                  organisation={organisation as OrganisationOUT}
                />
              )}
            </div>
            {organisation.status !== "pending" &&
            openOrganisation[index]?.status ? (
              <div
                className={`text-gray-700 hover:text-gray-900 ${
                  isFeatureVisible("urban_mining_hub") ? "pt-3" : ""
                }`}
              >
                {retoolMenus(organisation.id)}
                <BuildingsList organisation={organisation as OrganisationOUT} />
              </div>
            ) : null}
          </div>
        );
      })}
    </Collapse>
  );
};

export default observer(CollapseOrganisationsList);
