import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Input from "components/Input";
import SimpleSelect from "components/SimpleSelect";
import ImageUploader from "components/ImageUploader";
import Heading from "components/Heading";
import useForm from "hooks/useForm";
import { organisationsApi, OrganisationUpdateIN } from "api-client";
import { OfficeBuildingIcon } from "@heroicons/react/solid";
import SettingsLayout from "./SettingsLayout";
import { Organisation } from "../CollapseOrganisationsList";
import { organisationStore } from "store/OrganisationStore";
import { observer } from "mobx-react-lite";
import { userStore } from "store/UserStore";

type _Organisation = Omit<Organisation, "id">;

interface OrganisationLogo {
  id: string;
  image: string;
}
interface Org extends Omit<_Organisation, "logo"> {
  logo?: File[];
  selectedLogo?: OrganisationLogo[];
}

export default observer(function OrganisationAcccount({
  open,
  close,
}: {
  open: boolean;
  close: () => void;
}) {
  const { t } = useTranslation();
  const { organisationTypes, clickedOrganisation } = organisationStore;
  const [data, setData] = useState<Partial<Org>>({
    name: clickedOrganisation?.name,
    legal_name: clickedOrganisation?.legal_name,
    address: clickedOrganisation?.address,
    zip_code: clickedOrganisation?.zip_code,
    city: clickedOrganisation?.city,
    phone: clickedOrganisation?.phone,
    selectedLogo: clickedOrganisation?.logo
      ? [{ id: "1", image: clickedOrganisation?.logo }]
      : [],
  });

  const form = useForm(data, setData);

  const { authHeader } = userStore;

  async function update(name: string) {
    if (data[name as keyof Org] && name !== "logo") {
      const options: OrganisationUpdateIN = {
        name: name === "name" ? data.name : undefined,
        organisation_type_id:
          name === "organisation_type_id"
            ? data.organisation_type_id
            : undefined,
        legal_name: name === "legal_name" ? data.legal_name : undefined,
        address: name === "address" ? data.address : undefined,
        zip_code: name === "zip_code" ? String(data.zip_code) : undefined,
        city: name === "city" ? data.city : undefined,
        phone: name === "phone" ? data.phone : undefined,
      };
      if (!clickedOrganisation?.id || !authHeader) return;
      await organisationsApi
        .concularApiV1RoutersOrganisationsUpdateOrganisation(
          clickedOrganisation.id,
          options,
          authHeader
        )
        .then(() => organisationStore.getAllOrganisations())
        .catch((err) => console.log(err));
    }
  }

  const uploadLogo = async () => {
    if (data.logo?.length) {
      const logo = data.logo[0];
      if (!clickedOrganisation?.id || !authHeader) return;
      return await organisationsApi
        .concularApiV1RoutersOrganisationsUploadOrganisationLogo(
          clickedOrganisation.id,
          logo,
          authHeader
        )
        .then(async (response) => {
          await organisationStore.getAllOrganisations();
          if (!response.data?.logo) return;
          setData((state: Partial<Org>) => {
            const logo = response.data.logo as string;
            return {
              ...state,
              logo: undefined,
              selectedLogo: [{ id: "1", image: logo }],
            };
          });
        })
        .catch((err) => console.log(err));
    }
  };

  function Header() {
    return (
      <div className="flex p-5 border-b border-gray-300 items-center">
        <OfficeBuildingIcon width={20} />
        <Heading size="title" className="pl-2 !mb-0">
          {`${clickedOrganisation?.name} ${t(
            "organisationSettings.myOrganisation.settings"
          )}`}
        </Heading>
      </div>
    );
  }

  function logoTile() {
    return (
      <div className="border-b pb-2">
        <div className="font-semibold mb-6 text-lg">
          {t("organisationSettings.myOrganisation.logo")}
        </div>
        <ImageUploader
          name="logo"
          onChange={form.handleChange}
          images={data.selectedLogo ? data.selectedLogo : []}
        />
      </div>
    );
  }

  function organisationTile() {
    return (
      <div className="border-b pb-2 mt-4">
        <div className="font-semibold mb-6 text-lg">
          {t("organisationSettings.myOrganisation.organisation")}
        </div>
        <Input
          labelName={t("organisationSettings.myOrganisation.name")}
          placeholder={t("organisationSettings.myOrganisation.namePlaceholder")}
          noMargin
          value={data.name}
          name="name"
          className="mb-1"
          onChange={form.handleChange}
          onBlur={update}
          required
          infoText={t("organisationSettings.myOrganisation.nameInfos")}
        />
        {organisationTypes?.length > 0 && (
          <SimpleSelect
            labelName={t("organisationSettings.myOrganisation.companyType")}
            options={organisationTypes}
            name="organisation_type_id"
            placeholder={t(
              "organisationSettings.myOrganisation.companyTypePlaceholder"
            )}
            containerClassName="pt-3"
            onChange={form.handleChange}
            onBlur={update}
            current={clickedOrganisation?.organisation_type?.id}
          />
        )}
      </div>
    );
  }

  function contactTile() {
    return (
      <div className="pt-4">
        <div className="font-semibold mb-6 text-lg">
          {t("organisationSettings.myOrganisation.contact")}
        </div>
        <Input
          labelName={t("organisationSettings.myOrganisation.companyName")}
          name="legal_name"
          placeholder={t(
            "organisationSettings.myOrganisation.companyNamePlaceholder"
          )}
          noMargin
          className="mb-1"
          value={String(data.legal_name ?? "")}
          onChange={form.handleChange}
          onBlur={update}
          infoText={t("organisationSettings.myOrganisation.companyNameInfos")}
        />
        <Input
          labelName={t("organisationSettings.myOrganisation.companyAddress")}
          name="address"
          placeholder={t(
            "organisationSettings.myOrganisation.companyAddressPlaceholder"
          )}
          noMargin
          value={String(data.address ?? "")}
          containerClassName="mb-1 mt-4"
          onChange={form.handleChange}
          onBlur={update}
        />
        <div className="flex w-full justify-between gap-4">
          <Input
            labelName={t("organisationSettings.myOrganisation.zipCode")}
            name="zip_code"
            placeholder={t(
              "organisationSettings.myOrganisation.zipCodePlaceholder"
            )}
            noMargin
            value={String(data.zip_code === null ? "" : data.zip_code)}
            containerClassName="mb-1 mt-4 w-full"
            onChange={form.handleChange}
            onBlur={update}
          />
          <Input
            labelName={t("organisationSettings.myOrganisation.city")}
            name="city"
            placeholder={t(
              "organisationSettings.myOrganisation.cityPlaceholder"
            )}
            noMargin
            value={String(data.city ?? "")}
            containerClassName="mb-1 mt-4 w-full"
            onChange={form.handleChange}
            onBlur={update}
          />
        </div>
        <Input
          labelName={t("organisationSettings.myOrganisation.phone")}
          name="phone"
          placeholder={t(
            "organisationSettings.myOrganisation.phonePlaceholder"
          )}
          noMargin
          type="number"
          noArrow
          value={String(data.phone)}
          containerClassName="mt-4 pb-5 w-full"
          onChange={form.handleChange}
          onBlur={update}
        />
      </div>
    );
  }

  useEffect(() => {
    data.logo && uploadLogo();
  }, [data.logo]);

  return (
    <SettingsLayout open={open} close={close}>
      {Header()}

      <div className="p-5 text-sm h-[calc(100vh-200px)] overflow-y-scroll scrollbar">
        {logoTile()}
        {organisationTile()}
        {contactTile()}
      </div>
    </SettingsLayout>
  );
});
