import clsx from "clsx";
import ImageResizer from "features/Audit/ImageResizer";
import React from "react";
import { Organisation } from "./CollapseOrganisationsList";

export default function OrganisationLogo({
  organisation,
}: {
  organisation: Organisation;
}) {
  return (
    <div
      className={clsx(
        "text-white w-6 h-6 text-sm flex justify-center items-center rounded overflow-hidden",
        { "bg-indigo-700": !organisation?.logo }
      )}
    >
      {organisation?.logo ? (
        <ImageResizer
          src={organisation.logo ?? ""}
          alt={organisation.name}
          className="object-contain"
        />
      ) : (
        `${organisation.name?.substring(0, 1).toUpperCase()}`
      )}
    </div>
  );
}
