import React from "react";
import DatasetView, { EPDGroup } from "../DatasetView";
import { InventoryEPDGroupOUT } from "api-client";
import { searchStore } from "store/Mapping/SearchStore";

const ProductDetails = ({
  epdGroupsData,
}: {
  epdGroupsData?: InventoryEPDGroupOUT[];
}) => {
  const [epdGroups, setEpdGroups] = React.useState<EPDGroup[] | undefined>();

  function createEpdsList(group: InventoryEPDGroupOUT) {
    return group.epds?.map((item) => {
      return {
        factor: item.factor,
        epd_name: item.epd?.name_de ?? "-",
        dataset_source: item.epd?.dataset_source ?? "-",
        material_name: item.material ?? "-",
        dataset_url: item.epd?.url ?? "-",
        recyclability_score: item?.material_recyclability_score ?? "-",
        recyclability_qualification:
          item?.material_recyclability_qualification ?? "-",
        reference_unit: item.epd?.reference_unit ?? "-",
        reference_size: item.epd?.reference_size ?? "-",
        conformity: group?.conformity ?? "-",
        mass_value_m: item.epd?.mass_value_m ?? "-",
        mass_value_m2: item.epd?.mass_value_m2 ?? "-",
        mass_value_m3: item.epd?.mass_value_m3 ?? "-",
      };
    });
  }

  React.useEffect(() => {
    const preparedData = epdGroupsData?.map((group) => {
      const epdsList = createEpdsList(group);
      return {
        epds: epdsList,
        certifications: group.certifications,
        conformity: group.conformity,
        selectedGroupID:
          group.id === searchStore.currentDetailNode?.selected_epd_group_id,
      };
    });

    setEpdGroups(preparedData);
  }, [epdGroupsData]);

  return epdGroups?.map((item: EPDGroup, index) => (
    <DatasetView
      key={index}
      epdGroup={item}
      referenceUnitID={searchStore.currentDetailNode?.reference_unit}
    />
  ));
};

export default ProductDetails;
