import clsx from "clsx";
import { Organisation } from "./CollapseOrganisationsList";
import { IconLock } from "@tabler/icons-react";
import Tooltip from "components/Tooltip";
import { useTranslation } from "react-i18next";

export default function OrganisationPending({
  organisation,
}: {
  organisation: Organisation;
}) {
  const { t } = useTranslation();

  return (
    <div className="flex w-full justify-between items-center pointer-events-none opacity-50">
      <div className="flex items-center">
        <div
          className={clsx(
            "rounded bg-gray-400 text-white w-7 h-7 text-sm flex justify-center items-center"
          )}
        >
          <IconLock width={15} />
        </div>
        <div className="pl-3 whitespace-nowrap font-semibold">
          <Tooltip truncate className="max-w-[140px]">
            {organisation.name}
          </Tooltip>
        </div>
      </div>
      <span className="whitespace-nowrap text-xs">
        {t("onboarding.pending")}
      </span>
    </div>
  );
}
