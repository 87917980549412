import { ColumnDef, Row, SortingState } from "@tanstack/react-table";
import Modal, { ModalProps } from "components/Modal";
import SearchSortTable from "components/Table";
import NotFound from "components/NotFound";
import SearchBar from "features/MappingTools/ProductSearch/SearchBar";
import React from "react";
import { buildingProductStore } from "store/Mapping/BuildingProductStore";
import useColumns from "./useColumns";
import { observer } from "mobx-react-lite";
import SearchFilters, { Filters } from "../SearchFilters";
import { EPDSearchOUT, EPDSearchSortBy, SortOrder } from "api-client";
import { DropdownOption } from "components/DropdownCheckbox";
import Alert from "components/Alert";
import { useTranslation } from "react-i18next";
import Button from "components/Button";
import { IconUpload } from "@tabler/icons-react";
import { organisationStore } from "store/OrganisationStore";
import UploadEPDModal from "./UploadEPDModal";

interface ProductEditorEPDSearch extends ModalProps {
  datasetIndex: number;
  rowIndex: number;
}

type SortingProps = {
  sordOrder: SortOrder | null;
  sortBy: EPDSearchSortBy | null;
};

export default observer(function ProductEditorEPDSearch(
  props: ProductEditorEPDSearch
) {
  const { t } = useTranslation();
  const [term, setTerm] = React.useState<string>("");
  const [filters, setFilters] = React.useState<Filters>({
    dataset_source: [],
    conformity: [],
    epd_type: [],
  });
  const [sorting, setSorting] = React.useState<SortingProps>({
    sordOrder: null,
    sortBy: null,
  });
  const [hasDefaultDataSource, setHasDefaultDataSource] = React.useState<
    boolean | null
  >(null);
  const [openUploadEPD, setOpenUploadEPD] = React.useState<boolean>(false);

  const EPDs = buildingProductStore.epds;

  const onSearch = (value: string | number) => setTerm(String(value));

  const onSelectRow = (row: Row<unknown>) => {
    buildingProductStore.removeEpdsErrors(props.datasetIndex, props.rowIndex);
    buildingProductStore.removeMaterialErrors(
      props.datasetIndex,
      props.rowIndex
    );
    buildingProductStore.onChangeDataset(
      "epd",
      row?.original as EPDSearchOUT,
      props.datasetIndex,
      props.rowIndex
    );
    props.close();
  };

  const selectedRowID = () => {
    return buildingProductStore.data.groups?.[props.datasetIndex]?.epds?.[
      props.rowIndex
    ].epd?.id;
  };

  React.useEffect(() => {
    checkForDefaultDatasource();
  }, [buildingProductStore.filtersOptions.epd_data_sources.length]);

  React.useEffect(() => {
    return () => buildingProductStore.resetEPDs();
  }, []);

  React.useEffect(() => {
    (async () => await fetchData(0))();
  }, [term, filters, sorting]);

  function onSortChanged(sorting: SortingState) {
    const sortBy = sorting?.length ? sorting[0]?.id ?? null : null;
    const sortDirection = sorting?.length
      ? sorting[0]?.desc
        ? "DESC"
        : "ASC"
      : null;
    setSorting({ sortBy: sortBy as EPDSearchSortBy, sordOrder: sortDirection });
  }

  async function fetchData(start: number) {
    if (hasDefaultDataSource === null) return;
    const pageNumber = start / 25 + 1;
    const props = {
      term: term,
      conformity: getFilterItems("conformity"),
      dataset_source: getFilterItems("dataset_source"),
      data_type: getFilterItems("epd_type"),
    };
    buildingProductStore.setEPDs({
      ...buildingProductStore.epds,
      loading: true,
    });

    await buildingProductStore
      .fetchEPDs(pageNumber, props, sorting.sordOrder, sorting.sortBy)
      .then((response) => {
        response &&
          buildingProductStore.setEPDs({
            count: response.count,
            items: [
              ...(start ? buildingProductStore.epds.items : []),
              ...response.items,
            ],
            loading: false,
          });
      });
  }

  function getFilterItems(key: keyof Filters) {
    return filters[key]?.map((item) => String(item?.name)) ?? [];
  }

  function updateFilters(name: string, values: unknown[]) {
    setFilters((state) => ({ ...state, [name]: values }));
  }

  function checkForDefaultDatasource() {
    const defaultDataSource = buildingProductStore.getDefaultEPDDatasetName(
      props.datasetIndex
    );
    if (buildingProductStore.getDefaultEPDDatasetName(props.datasetIndex)) {
      const defaultItem =
        buildingProductStore.filtersOptions.epd_data_sources.find(
          (item: DropdownOption) => item?.name === defaultDataSource
        );
      defaultItem && updateFilters("dataset_source", [defaultItem]);
      setHasDefaultDataSource(true);
    } else {
      setHasDefaultDataSource(false);
    }
  }

  const scrollAreaHeight = `!max-h-[calc(100vh-${
    hasDefaultDataSource ? 450 : 350
  }px)]`;

  return (
    <Modal
      isOpen={props.open}
      setIsOpen={props.close}
      className="p-6"
      containerClassName="md:min-w-[1050px] min-h-[500px] overflow-visible overflow-x-hidden"
      position="top"
    >
      <SearchBar
        value={term}
        onChange={onSearch}
        onCancel={() => {
          props.close();
        }}
      />
      <SearchFilters
        filters={filters}
        setFilters={updateFilters}
        dataSourceDisabled={Boolean(hasDefaultDataSource)}
      />
      {hasDefaultDataSource ? (
        <Alert
          type="attention"
          description={t("productEditor.epdSearchAlert")}
          className="my-4"
        />
      ) : null}

      <Alert
        type="info"
        className="items-center mb-6 !py-2"
        titleClassName="pt-1"
        title={
          <div className="flex justify-between text-indigo-800 items-center">
            {t("productEditor.missingEPDAlert")}
            <Button
              type="link"
              onClick={() => setOpenUploadEPD(true)}
              className="text-indigo-700 p-0 hover:!text-indigo-900"
              leadingIcon={<IconUpload />}
              width="fit-content"
            >
              {t("productEditor.uploadEPD.title")}
            </Button>
          </div>
        }
      />
      {EPDs.items.length || EPDs.loading ? (
        <SearchSortTable
          borderedStyle
          getColumns={useColumns as () => ColumnDef<unknown, unknown>[]}
          data={EPDs.items}
          onRowClick={onSelectRow}
          selectedRow={selectedRowID()}
          hasHoverStyle
          zebraStriped
          fetchData={fetchData}
          totalDBRowCount={EPDs.count}
          scrollDivClassName={scrollAreaHeight}
          skeletonClassName={scrollAreaHeight}
          dataFetchLoading={EPDs.loading}
          headerColumnStyle="!h-[60px]"
          headerRowStyle="text-sm font-semibold"
          skeletonLoading={EPDs.loading && EPDs.count === 0}
          fullHeightSkeleton
          onSortChanged={onSortChanged}
        />
      ) : (
        <NotFound />
      )}
      {openUploadEPD && (
        <UploadEPDModal
          open={openUploadEPD}
          close={() => setOpenUploadEPD(false)}
        />
      )}
    </Modal>
  );
});
