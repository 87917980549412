import React from "react";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { IconArrowsSort, IconChevronDown, IconPlus } from "@tabler/icons-react";
import SortMenu from "./SortMenu";
import { PropertyBrowserTreeNode } from "api-client";
import { dynamicMEStore } from "store/Mapping/DMEStore";
import SimpleColumnsMenu from "./SimpleExistingMenu";
import { useTranslation } from "react-i18next";
import { EMPTY_SORT_ROW } from "../../const";
import useOutsideClick from "hooks/useOutsideClick";

export interface SortProps {
  id: string;
  property: PropertyBrowserTreeNode | undefined;
  sort: string;
}

export default observer(function SortByPropertyBrowser(props: {
  advancedMode?: boolean;
}) {
  const { t, i18n } = useTranslation();
  const isEng = i18n.language === "en";
  const openIconRef = React.useRef<HTMLDivElement>(null);
  const selectedPropertiesKeys = new Set(
    dynamicMEStore.selectedSortedByItems.map(
      (item) => item.property?.column_key
    )
  );

  const filteredItems = dynamicMEStore.visibleColumnProperties.filter(
    (item) => !selectedPropertiesKeys.has(item.column_key)
  );
  const closeView = () => dynamicMEStore.setOpenSort(false);
  useOutsideClick(openIconRef, closeView);

  const handleOpenMenu = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    dynamicMEStore.setOpenSort(!dynamicMEStore.openSort);
  };

  const addFirstSortRule = (value: PropertyBrowserTreeNode) => {
    dynamicMEStore.setSelectedSortedByItems([EMPTY_SORT_ROW]);
    onChangeProperty(value, 0, true);
  };

  const onChangeProperty = (
    value: PropertyBrowserTreeNode,
    index: number,
    openMenu?: boolean
  ) => {
    const rows = [...dynamicMEStore.selectedSortedByItems];
    rows[index].property = value;
    dynamicMEStore.setSelectedSortedByItems([...rows]);
    openMenu && dynamicMEStore.setOpenSort(true);
  };

  const clientRect = openIconRef.current?.getBoundingClientRect();

  function labelContent() {
    return (
      <div className="flex items-center">
        <IconArrowsSort width={17} className="mr-1" stroke={3} />
        <span className="mr-1">
          {dynamicMEStore.validSortingRules?.length
            ? `${t("mappingEditor.sorted")} ${t("mappingEditor.by")}: `
            : t("mappingEditor.sort")}
        </span>
        {dynamicMEStore.selectedSortedByItems
          .map((item) => {
            if (item.property)
              return isEng ? item.property?.name : item.property?.name_de;
          })
          .filter((rec) => rec)
          .join(", ")}
      </div>
    );
  }

  const hasSortingRules = Boolean(dynamicMEStore.validSortingRules?.length);

  return (
    <div
      className="flex items-center"
      ref={openIconRef}
      onClick={handleOpenMenu}
    >
      {hasSortingRules && !props.advancedMode && labelContent()}
      <div className="overflow-visible">
        {hasSortingRules ? (
          <>
            <IconChevronDown
              className={clsx(
                "h-5 w-5 cursor-pointer",
                dynamicMEStore.openSort ? "rotate-180" : ""
              )}
            />
            {dynamicMEStore.openSort ? (
              <SortMenu
                cursorPosition={{
                  x: Number(clientRect?.x),
                  y: Number(clientRect?.y),
                }}
                items={filteredItems}
                onChangeProperty={onChangeProperty}
              />
            ) : null}
          </>
        ) : (
          <div className="relative">
            <SimpleColumnsMenu<PropertyBrowserTreeNode>
              isEng={isEng}
              handleSelect={(value) => addFirstSortRule(value)}
              items={filteredItems}
              placeholder={t("mappingEditor.findProperty")}
              icon={
                <>
                  {!props.advancedMode && labelContent()}
                  <IconPlus className="h-4 w-4 cursor-pointer" />
                </>
              }
              isOpen={(open: boolean) => dynamicMEStore.setOpenSort(open)}
            />
          </div>
        )}
      </div>
    </div>
  );
});
