import React from "react";
import Modal, { ModalProps } from "components/Modal";
import { useTranslation } from "react-i18next";
import { organisationStore } from "store/OrganisationStore";
import Button from "components/Button";
import TextInput from "components/TextInput";
import { IconLink } from "@tabler/icons-react";
import { buildingProductStore } from "store/Mapping/BuildingProductStore";
import useToast from "hooks/useToast";

const UploadEPDModal = (props: ModalProps) => {
  const { t } = useTranslation();
  const [epdLink, setEpdLink] = React.useState<string>("");
  const [error, setError] = React.useState(false);
  const toast = useToast();

  const isValidURL = (str: string): boolean => {
    try {
      new URL(str);
      return true;
    } catch {
      return false;
    }
  };

  const onChangeLink = (value: string) => {
    setEpdLink(value);
    setError(!isValidURL(value));
  };

  const submitEPDLink = async () => {
    await buildingProductStore
      .submitCustomEPDRequest(epdLink)
      .then(() => {
        toast(t("productEditor.uploadEPD.successMsgOnSubmit"), "success");
        setEpdLink("");
        props.close();
      })
      .catch((error) => toast(error.message, "warning"));
  };

  function footerContent(): React.ReactNode {
    return (
      <div className="w-full flex items-end justify-between">
        <Button width="fit-content" type="gray" onClick={props.close}>
          {t("commons.cancel")}
        </Button>
        <span className="p-4" />
        <Button
          width="fit-content"
          type="primary"
          disable={!epdLink || error}
          onClick={submitEPDLink}
        >
          {t("productEditor.uploadEPD.submitBtn")}
        </Button>
      </div>
    );
  }

  return (
    <Modal
      isOpen={props.open}
      setIsOpen={props.close}
      title={t("productEditor.uploadEPD.title")}
      closeButton
      containerClassName="min-w-[768px] text-gray-900"
      footer={footerContent()}
    >
      <div className="p-6 text-sm">
        <div className="mb-4">{t("productEditor.uploadEPD.description")}</div>
        <div className="flex items-center mb-4">
          <div className="bg-indigo-600 text-white min-w-[32px] h-8 rounded-full flex justify-center items-center mr-4">
            1
          </div>
          <div>
            <div className="text-base font-semibold mb-2">
              {t("productEditor.uploadEPD.step1")}
            </div>
            <div
              className="mb-3"
              dangerouslySetInnerHTML={{
                __html: t("productEditor.uploadEPD.step1Subtitle", {
                  org_id: organisationStore.currentOrganisation?.id,
                }),
              }}
            />
            <TextInput
              placeholder={t("productEditor.uploadEPD.linkPlaceholder")}
              unit={<IconLink />}
              value={epdLink}
              handleChange={onChangeLink}
              hasError={error}
            />
          </div>
        </div>
        <div className="flex items-center mb-4">
          <div className="bg-indigo-600 text-white min-w-[32px] h-8 rounded-full flex justify-center items-center mr-4">
            2
          </div>
          <div>
            <div className="text-base font-semibold mb-2">
              {t("productEditor.uploadEPD.step2")}
            </div>
            <div>{t("productEditor.uploadEPD.step2Subtitle")}</div>
          </div>
        </div>
        <div className="flex items-center">
          <div className="bg-indigo-600 text-white min-w-[32px] h-8 rounded-full flex justify-center items-center mr-4">
            3
          </div>
          <div>
            <div className="text-base font-semibold mb-2">
              {t("productEditor.uploadEPD.step3")}
            </div>
            <div>{t("productEditor.uploadEPD.step3Subtitle")}</div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default UploadEPDModal;
