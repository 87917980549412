import React, { useEffect, useRef, useState, Fragment } from "react";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { Tab } from "@headlessui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import { classNames } from "utils";
import { useWindowSize } from "hooks/useWindowSize";

interface Item {
  item: string;
  path?: string;
  content?: React.ReactNode;
}

interface TabsProps {
  options: Item[];
  currentIndex?: number;
  onChange?: (index: number) => void;
  vertical?: boolean;
  panelClassName?: string;
  listClassName?: string;
  buttonTabStyle?: boolean;
  children?: React.ReactNode;
  isLink?: boolean;
  containerClassName?: string;
  showArrows?: boolean;
}

const Tabs = ({
  options,
  currentIndex,
  onChange,
  vertical,
  panelClassName,
  listClassName,
  buttonTabStyle,
  children,
  isLink,
  containerClassName,
  showArrows,
}: TabsProps) => {
  const navigate = useNavigate();

  const [hasOverflow, setHasOverflow] = useState(false);
  const tabListRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const windowSize = useWindowSize();

  const handleScroll = (type: "right" | "left") =>
    type === "right"
      ? tabListRef?.current?.scrollTo(tabListRef?.current?.scrollLeft + 100, 0)
      : tabListRef?.current?.scrollTo(tabListRef?.current?.scrollLeft - 100, 0);

  const checkOverflow = () => {
    const overflow =
      Number(tabListRef?.current?.scrollWidth) >
      Number(containerRef?.current?.clientWidth);
    hasOverflow !== overflow && setHasOverflow(overflow);
  };

  useEffect(() => {
    checkOverflow();
  }, [windowSize.width]);

  useEffect(() => {
    currentIndex === 0 && tabListRef?.current?.scrollTo(0, 0);
  }, [currentIndex]);

  const isArrowVisible = hasOverflow || showArrows;

  const navigateToPath = (index: number) =>
    navigate(`${options[index].path}`, { replace: true });

  return (
    <Tab.Group
      onChange={(index) => {
        isLink ? navigateToPath(index) : null;
        onChange?.(index);
      }}
      vertical={vertical}
      selectedIndex={currentIndex}
    >
      <div
        className={classNames(
          vertical ? "flex" : "relative bg-white",
          buttonTabStyle ? "border-b-0" : "border-b border-gray-300",
          hasOverflow ? "px-7" : "px-3",
          containerClassName ?? ""
        )}
        ref={containerRef}
      >
        <Tab.List
          className={clsx(
            "-mb-px overflow-auto flex space-x-8 border-t-0 border-gray-300",
            {
              "flex-col justify-items-start": vertical,
              " transition overflow-x-auto scrollbar-hide scroll-smooth":
                !vertical,
            },
            listClassName
          )}
          ref={tabListRef}
        >
          {isArrowVisible && (
            <ChevronLeftIcon
              width={25}
              className="absolute text-gray-500 left-0 top-[20%] md:top-3 bg-white cursor-pointer hover:text-gray-600 py-0.5 px-0"
              onClick={() => handleScroll("left")}
            />
          )}
          {options.map((i, index) => (
            <Tab key={index} as={Fragment}>
              {({ selected }) => {
                return (
                  <button
                    className={clsx(
                      "block pr-3 !px-0 !ml-0 text-base border-gray-300 focus:outline-none",
                      " md:text-sm rounded-md focus:ring-indigo-500 focus:border-indigo-500",
                      {
                        "!ml-0 text-left": vertical,
                        "border-gray-200 border first-of-type:rounded-l-md rounded-none last-of-type:rounded-r-md !mr-0 grow":
                          buttonTabStyle,
                        "bg-indigo-600": buttonTabStyle && selected,
                      }
                    )}
                    onClick={() =>
                      isLink ? navigate(`${i.path}`, { replace: true }) : null
                    }
                    test-id={i.path}
                  >
                    <div
                      className={clsx(
                        "whitespace-nowrap px-4 py-2 font-medium text-sm min-h-full flex justify-center items-center",
                        {
                          "border-indigo-600 border-b text-indigo-600":
                            selected && !buttonTabStyle,
                          "text-white": selected && buttonTabStyle,
                          "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300":
                            !selected && !vertical,
                        }
                      )}
                    >
                      {i.item}
                    </div>
                  </button>
                );
              }}
            </Tab>
          ))}
          {isArrowVisible && (
            <ChevronRightIcon
              width={25}
              className="absolute text-gray-500 right-0 top-[20%] md:top-3 bg-white cursor-pointer hover:text-gray-600 py-0.5"
              onClick={() => handleScroll("right")}
            />
          )}
        </Tab.List>
        <Tab.Panels className={clsx("w-full", panelClassName)}>
          {options.map((i, index) =>
            isLink ? (
              <Tab.Panel key={index}>{children}</Tab.Panel>
            ) : (
              <Tab.Panel key={index}>{i.content}</Tab.Panel>
            )
          )}
        </Tab.Panels>
      </div>
    </Tab.Group>
  );
};

export default Tabs;
