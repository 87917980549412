import FilesDropZone from "components/FilesDropZone";
import React from "react";
import ExcelIcon from "assets/images/icons/xlsx-file-icon.svg";
import { useTranslation } from "react-i18next";
import { IconDownload, IconFileSpreadsheet } from "@tabler/icons-react";
import { useParams } from "react-router";
import useToast from "hooks/useToast";
import { b6ImporterStore } from "store/B6ImporterStore";
import Alert from "components/Alert";
import {
  NON_RESIDENTIAL_BUILDING_FILE,
  RESIDENTIAL_BUILDING_FILE,
} from "utils";
import { observer } from "mobx-react-lite";
import Tooltip from "components/Tooltip";

const ImporterSidebar = () => {
  const { t } = useTranslation();
  const [currentFile, setCurrentFile] = React.useState<File>();
  const { id } = useParams();
  const toast = useToast();

  React.useEffect(() => {
    (async () => await uploadFile())();
    return () => setCurrentFile?.(undefined);
  }, [currentFile]);

  function onUploadError(err: unknown) {
    b6ImporterStore.setUploading(false);
    console.error(err);
    if (err instanceof Error) {
      toast(err.message, "warning");
    }
  }

  async function uploadFile() {
    if (!currentFile) return;
    if (id) {
      (async () => {
        try {
          const response = await b6ImporterStore.uploadCsvFile(currentFile);
          if (response) {
            const timer = setTimeout(() => {
              toast(t("buildingMaterialResources.uploadSucces"), "success");
              b6ImporterStore.setUploadedFileID(response.report_id);
              clearTimeout(timer);
            }, 1000);
          }
        } catch (err: unknown) {
          onUploadError(err);
        }
      })();
    }
  }

  function step1() {
    return (
      <div className="mb-6">
        <div className={titleClasses}>{t("b6Importer.step1Title")}</div>
        <div className="text-xs font-normal text-gray-700 mb-3">
          {t("b6Importer.step1Subtitle")}
        </div>
        <div className="border border-gray-300 rounded-md mb-3">
          <a
            className="flex justify-between items-center p-2 border-b border-gray-300 text-gray-700 text-sm font-medium"
            href={RESIDENTIAL_BUILDING_FILE}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="flex items-center">
              <IconFileSpreadsheet className="text-gray-500 mr-1" />
              {t("b6Importer.downloadExcel1")}
            </div>
            <IconDownload className="cursor-pointer" />
          </a>
          <a
            className="flex justify-between items-center p-2 border-gray-300 text-gray-700 text-sm font-medium"
            href={NON_RESIDENTIAL_BUILDING_FILE}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="flex items-center">
              <IconFileSpreadsheet className="text-gray-500 mr-1" />
              {t("b6Importer.downloadExcel2")}
            </div>
            <IconDownload className="cursor-pointer" />
          </a>
        </div>
        {b6ImporterStore.fileReport?.download_url ? (
          <>
            <div className="text-sm font-semibold text-gray-600 mb-2">
              {t("b6Importer.lastUploadedFile")}
            </div>
            <a
              className="flex justify-between items-center p-2 border rounded-md border-gray-300 text-gray-700 text-sm font-medium"
              href={b6ImporterStore.fileReport?.download_url as string}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="flex items-center">
                <IconFileSpreadsheet className="text-gray-500 mr-1" />
                {b6ImporterStore.fileReport?.input_file ? (
                  <Tooltip truncate>
                    {b6ImporterStore.fileReport?.input_file}
                  </Tooltip>
                ) : (
                  "-"
                )}
              </div>
              <IconDownload className="cursor-pointer" />
            </a>
          </>
        ) : null}
      </div>
    );
  }

  function step2() {
    return (
      <div className="mb-6">
        <div className={titleClasses}>{t("b6Importer.step2Title")}</div>
        <div className={subTitleClasses}>{t("b6Importer.step2Subtitle")}</div>
      </div>
    );
  }

  function step3() {
    return (
      <div>
        <div className={titleClasses}>{t("b6Importer.step3Title")}</div>
        <div className={`${subTitleClasses} mb-3`}>
          {t("b6Importer.step3Subtitle")}
        </div>
        {b6ImporterStore.fileReport ? (
          <Alert
            type="info"
            description={t("b6Importer.uploadAlert")}
            className="mb-3"
          ></Alert>
        ) : null}
        <FilesDropZone
          setFile={setCurrentFile}
          icon={ExcelIcon}
          acceptedTypes={[".xlsx"]}
          dragMessage={t("b6Importer.dragAndDropMessage")}
          buttonText={t("b6Importer.uploadExcelFile")}
        />
      </div>
    );
  }

  const titleClasses = "text-base font-semibold mb-2 text-gray-800";
  const subTitleClasses = "text-xs font-normal mb-2 text-gray-700";

  return (
    <div className="p-6 border-gray-100 border-r w-[700px] overflow-y-auto max-h-[calc(100vh-240px)]">
      {step1()}
      {step2()}
      {step3()}
    </div>
  );
};

export default observer(ImporterSidebar);
