import React, { ReactNode } from "react";
import { Combobox } from "@headlessui/react";
import clsx from "clsx";
import { IconChevronDown } from "@tabler/icons-react";
import OptionsMenu from "./OptionsMenu";
import Tooltip from "components/Tooltip";

export interface DropdownOption {
  id?: string | number;
  name: string;
  name_de?: string;
  icon?: ReactNode;
  title?: string;
  title_de?: string;
  value?: string;
  children?: DropdownOption[];
}

export interface DropdownWithSearchProps<T>
  extends React.HTMLProps<HTMLInputElement> {
  items?: T[];
  displayKey: keyof T;
  detailKey?: keyof T;
  handleSelect?: (value: T[], name?: string) => void;
  containerClassName?: string;
  optionsClassName?: string;
  labelWithSearch?: boolean;
  hideOptionsSearch?: boolean;
  checkedItems?: T[];
  searchClassName?: string;
  disabled?: boolean;
  singleSelect?: boolean;
  hasSelectInput?: boolean;
  selectInputClassName?: string;
  fixedPos?: boolean;
  titleOrIcon?: React.ReactNode;
  isOpen?: (open: boolean) => void;
  wrapperID?: string;
  leftOffset?: number;
  parentScrollTop?: number;
  scrollAreaHeight?: number;
}

export default function DropdownBrowser<Item extends DropdownOption>(
  props: DropdownWithSearchProps<Item>
) {
  const [openMenu, setOpenMenu] = React.useState(false);
  const [selectedItems, setSelectedItems] = React.useState<Item[]>(
    props.checkedItems ?? []
  );
  const [cursorPosition, setCursorPosition] = React.useState({ x: 0, y: 0 });
  const inputRef = React.useRef<HTMLDivElement>(null);
  const comboBtn = React.useRef<HTMLButtonElement>(null);

  React.useEffect(() => {
    props.checkedItems && setSelectedItems(props.checkedItems);
  }, [props.checkedItems]);

  React.useEffect(() => {
    props.isOpen?.(openMenu);
  }, [openMenu]);

  const handleOnClick = (e: { clientX: number; clientY: number }) => {
    setCursorPosition({ x: e.clientX, y: e.clientY });
  };

  function buttonContent(open: boolean) {
    // Delay the state update to happens after rerendering
    setTimeout(() => {
      setOpenMenu(open);
    }, 0);

    const currentValue = selectedItems.length
      ? selectedItems.map((item) => item[props.displayKey]).join(", ")
      : props.placeholder;

    return (
      <div className="flex items-center w-full">
        {props.hasSelectInput ? (
          <div
            className={clsx(
              "border-none bg-transparent h-auto p-0 mr-0.5 text-sm",
              props.selectInputClassName,
              { "text-gray-500 text-sm": !selectedItems.length }
            )}
          >
            <Tooltip content={currentValue} truncate>
              {currentValue}
            </Tooltip>
          </div>
        ) : null}
        {props.titleOrIcon ? (
          props.titleOrIcon
        ) : (
          <IconChevronDown
            className={clsx("h-[17px] w-[17px]", open ? "rotate-180" : "")}
          />
        )}
      </div>
    );
  }

  const closeView = () => {
    setOpenMenu(false);
    comboBtn.current?.click();
  };

  return (
    <div
      className={clsx(props.containerClassName, "rounded-md")}
      ref={inputRef}
    >
      <Combobox>
        <Combobox.Button
          className={clsx(
            props.hasSelectInput
              ? "flex items-center rounded-md border border-gray-300 px-2 py-1.5 h-9"
              : "flex items-center w-full"
          )}
          aria-label={props.name}
          onClick={handleOnClick}
          ref={comboBtn}
        >
          {({ open }) => buttonContent(open)}
        </Combobox.Button>
        {openMenu ? (
          <OptionsMenu<Item>
            {...props}
            inputRef={inputRef}
            setSelectedItems={setSelectedItems}
            cursorPosition={cursorPosition}
            selectedItems={selectedItems}
            closeMenu={closeView}
          />
        ) : null}
      </Combobox>
    </div>
  );
}
