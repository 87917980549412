import React from "react";
import { observer } from "mobx-react-lite";
import GroupByPropertyBrowser from "../GroupByPropertyBrowser";
import FilterByPropertyBrowser from "../FilterByPropertyBrowser";
import SortByPropertyBrowser from "../SortByPropertyBrowser";

function SimpleView() {
  const selectedItemStyle =
    "flex text-indigo-700 bg-indigo-100 transition hover:bg-indigo-200 rounded-3xl px-2 py-[1px] items-center mr-2 cursor-pointer";

  return (
    <div className="flex">
      <div className={selectedItemStyle}>
        <GroupByPropertyBrowser />
      </div>
      <div className={selectedItemStyle}>
        <FilterByPropertyBrowser />
      </div>
      <div className={selectedItemStyle}>
        <SortByPropertyBrowser />
      </div>
    </div>
  );
}

export default observer(SimpleView);
