import React from "react";
import { observer } from "mobx-react-lite";
import { dynamicMEStore } from "store/Mapping/DMEStore";
import GroupByMenu from "./GroupByMenu";
import { IconChevronDown, IconPlus } from "@tabler/icons-react";
import clsx from "clsx";
import DropdownBrowser from "components/DropdownBrowser";
import { PropertyBrowserTreeNode } from "api-client";
import { useTranslation } from "react-i18next";
import { CollectionIcon } from "@heroicons/react/outline";
import useOutsideClick from "hooks/useOutsideClick";

export default observer(function GroupByPropertyBrowser(props: {
  advancedMode?: boolean;
}) {
  const { t, i18n } = useTranslation();
  const isEng = i18n.language === "en";
  const openIconRef = React.useRef<HTMLDivElement>(null);
  const filteredItems = dynamicMEStore.filterNotSelectedItems(
    dynamicMEStore.properties,
    dynamicMEStore.selectedGroupByItems
  );
  const closeView = () => dynamicMEStore.setOpenGroupBy(false);
  useOutsideClick(openIconRef, closeView);

  const handleOpenMenu = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    dynamicMEStore.setOpenGroupBy(!dynamicMEStore.openGroupBy);
  };

  const onSelectColumn = (selectedItems: PropertyBrowserTreeNode[]) => {
    dynamicMEStore.setSelectedGroupByItems(selectedItems);
    dynamicMEStore.reOrderGroupingColumns();
  };

  const clientRect = openIconRef.current?.getBoundingClientRect();

  function labelContent() {
    return (
      <div className="flex items-center">
        <CollectionIcon width={17} className="mr-1" strokeWidth={3} />
        <span className="mr-1">
          {dynamicMEStore.validGroupByRules?.length
            ? `${t("mappingEditor.grouped")} ${t("mappingEditor.by")}: `
            : t("mappingEditor.group")}
        </span>
        {dynamicMEStore.selectedGroupByItems
          .map((item) => (isEng ? item.name : item.name_de))
          .join(", ")}
      </div>
    );
  }

  const hasGrouping = Boolean(dynamicMEStore.validGroupByRules?.length);

  return (
    <div
      className="flex items-center"
      ref={openIconRef}
      onClick={handleOpenMenu}
    >
      {hasGrouping && !props.advancedMode && labelContent()}
      <div className="overflow-visible">
        {hasGrouping ? (
          <>
            <IconChevronDown
              className={clsx(
                "h-5 w-5 cursor-pointer",
                dynamicMEStore.openGroupBy ? "rotate-180" : ""
              )}
            />
            {dynamicMEStore.openGroupBy ? (
              <GroupByMenu
                cursorPosition={{
                  x: Number(clientRect?.x),
                  y: Number(clientRect?.y),
                }}
                items={filteredItems}
                onSelectColumn={onSelectColumn}
              />
            ) : null}
          </>
        ) : (
          <DropdownBrowser<PropertyBrowserTreeNode>
            displayKey={isEng ? "name" : "name_de"}
            detailKey={isEng ? "classification" : "classification_de"}
            handleSelect={onSelectColumn}
            items={filteredItems}
            checkedItems={dynamicMEStore.selectedGroupByItems}
            placeholder={t("mappingEditor.findProperty")}
            optionsClassName="!max-w-fit min-w-[246px] !right-0"
            titleOrIcon={
              <>
                {!props.advancedMode && labelContent()}
                <IconPlus
                  className={clsx("h-4 w-4 cursor-pointer", {
                    "ml-2": props.advancedMode,
                  })}
                />
              </>
            }
            isOpen={(open: boolean) => dynamicMEStore.setOpenGroupBy(open)}
          />
        )}
      </div>
    </div>
  );
});
