import React from "react";
import Logo from "assets/images/circularLCA_logo-new.svg";
import LogoOne from "assets/images/one_logo.svg";
import { IS_CONCULAR_ONE, classNames } from "utils";
import BuildingHeader from "features/Building/BuildingHeader";
import { MenuIcon } from "@heroicons/react/solid";
import { useNavigate } from "react-router";

const Header = ({
  sidebarOpen,
  toggleSidebar,
  headerTitle,
}: {
  sidebarOpen: boolean;
  toggleSidebar: () => void;
  headerTitle?: string;
}) => {
  const navigate = useNavigate();

  if (IS_CONCULAR_ONE)
    return (
      <div
        className={`${
          !sidebarOpen ? "visible" : "hidden"
        } flex items-center absolute px-4 py-[13px]`}
      >
        <img
          src={LogoOne}
          alt="Concular"
          className="h-6 w-auto cursor-pointer"
          onClick={() => navigate("/")}
        />
        <div
          id="menuToggler"
          className="h-full w-[30px] min-w-[30px] cursor-pointer flex justify-center"
          test-id="menu_toggler"
        >
          <MenuIcon
            onClick={toggleSidebar}
            className=" text-gray-500 h-full rounded p-[6px] hover:text-gray-900"
          />
        </div>
      </div>
    );

  return (
    <header
      className={classNames(
        "h-10 md:h-12 text-black !bg-white items-center border-b",
        "border-b-gray-300 w-full z-20 top-0 sticky flex print:hidden"
      )}
    >
      <div
        className={`${
          !sidebarOpen ? "visible" : "hidden"
        } flex items-center h-full`}
      >
        <div
          id="menuToggler"
          className="h-full w-[50px] min-w-[50px] cursor-pointer flex justify-center border-r border-r-gray-300"
          test-id="menu_toggler"
        >
          <MenuIcon
            onClick={toggleSidebar}
            className="text-gray-500 h-full w-full hover:bg-gray-100 rounded p-[10px]"
          />
        </div>
        <div className="w-[108px] h-6 logo-wrapper cursor-pointer ml-2 hidden md:flex items-center">
          <img
            src={Logo}
            height={20}
            width={108}
            alt="Concular"
            onClick={() => navigate("/")}
          />
        </div>
      </div>
      {headerTitle ? (
        <div className="text-sm md:text-base font-semibold leading-4 px-4">
          {headerTitle}
        </div>
      ) : (
        <BuildingHeader />
      )}
    </header>
  );
};

export default Header;
