import React from "react";
import clsx from "clsx";
import SidebarTop from "./SidebarTop";
import SidebarBottom from "./SidebarBottom";
import CollapseOrganisationsList from "features/Organisation/CollapseOrganisationsList";
import OrganisationsList from "features/Organisation/OrganisationsList";
import { IS_CONCULAR_ONE } from "utils";
import SharedBuildingsList from "features/Building/BuildingsList/SharedBuildingsList";

const Sidebar = ({
  open,
  toggleSidebar,
}: {
  open: boolean;
  toggleSidebar: () => void;
}) => {
  return (
    <div
      className={clsx(
        "min-h-screen max-h-screen bg-white fixed z-30 flex flex-col",
        "content-between top-0 w-[300px] transition-all border-r border-gray-300",
        { "-ml-[300px]": !open }
      )}
    >
      <SidebarTop toggleSidebar={toggleSidebar} />
      <div
        className={clsx(
          "flex-1 z-10 inset-y-0 flex min-h-full flex-col",
          "h-auto flex-col border-gray-300 bg-white"
        )}
      >
        <nav
          className={clsx(
            "max-h-screen pb-2 flex flex-col overflow-y-auto overflow-x-clip border-t border-gray-300",
            "overflow-x-visible space-y-1 flex-1 min-w-full scrollbar",
            { "border-t-0": IS_CONCULAR_ONE }
          )}
        >
          {IS_CONCULAR_ONE ? (
            <OrganisationsList />
          ) : (
            <CollapseOrganisationsList />
          )}
          {!IS_CONCULAR_ONE && <SharedBuildingsList />}
        </nav>
      </div>
      <SidebarBottom />
    </div>
  );
};

export default React.memo(Sidebar);
