import Button from "components/Button";
import Modal, { ModalProps } from "components/Modal";
import React from "react";
import { useTranslation } from "react-i18next";
import CheckCircleIcon from "assets/images/check-circle-green.svg";
import { LoadingView } from "features/Audit/LoadingView";

interface IProps extends ModalProps {
  maxBuildingNo: number;
  sampleBuildingStatus: string | null;
  onAddSampleBuilding: () => void;
}

const MaxBuildingCountAlert = (props: IProps) => {
  const { t } = useTranslation();

  const initialContent = (
    <div>
      <p className="px-6 pt-6 font-[400] text-[14px]">
        {t("dashboard.max_building_message", {
          maxBuildingNo: props.maxBuildingNo,
        })}
      </p>
      <div className="mt-6 px-6 flex w-full justify-between py-4 bg-gray-50">
        <Button
          className="font-[500] text-[16px]"
          width="fit-content"
          onClick={props.onAddSampleBuilding}
          type="primary"
        >
          {t("onboarding.addDemoBuilding")}
        </Button>
        <Button
          className="font-[500] text-[16px]"
          width="fit-content"
          onClick={props.close}
          type="primary"
        >
          {t("dashboard.btn_i_understand")}
        </Button>
      </div>
    </div>
  );

  const inProgressContent = (
    <div>
      {props.sampleBuildingStatus == "in_progress" && (
        <div className="px-6 pt-6">
          <LoadingView message="" />
        </div>
      )}
      {props.sampleBuildingStatus == "done" && (
        <div className="w-full px-6 pt-6 font-[400] text-[14px]">
          <img
            className="w-12 inline mr-4"
            src={CheckCircleIcon}
            alt="check-circle"
          />
          <p className="inline">{t("onboarding.addDemoBuildingSuccess")}</p>
        </div>
      )}
      <div className="mt-6 px-6 flex w-full justify-end py-4 bg-gray-50">
        <Button
          className="font-[500] text-[16px]"
          width="fit-content"
          onClick={props.close}
          type="primary"
        >
          {t("commons.close")}
        </Button>
      </div>
    </div>
  );

  return (
    <Modal
      isOpen={props.open}
      setIsOpen={props.close}
      closeButton
      title={t("dashboard.max_building_heading")}
      disableOutsideClick
      containerClassName="sm:my-8 sm:max-w-sm sm:w-full md:max-w-xl"
    >
      {props.sampleBuildingStatus ? inProgressContent : initialContent}
    </Modal>
  );
};

export default MaxBuildingCountAlert;
