import { ReportOUT, operationalEnergyApi } from "api-client";
import { makeAutoObservable } from "mobx";
import { buildingStore } from "./Building/BuildingStore";
import { getLocalAuthHeader } from "api-client-local/utils";
import { AxiosProgressEvent } from "axios";
import { BGItem } from "components/ButtonGroups";

interface UploadError {
  error?: string;
  columns?: string[];
  sheet?: string;
}

class B6Importer {
  uploading: boolean = false;
  uploadProgress: number = 0;
  fileReport: ReportOUT | undefined | null = undefined;
  uploadedFileID: string | undefined = undefined;
  reportType: BGItem | undefined;
  uploadError: UploadError | undefined;
  hasLatestConfirmedReport: boolean = false;

  setUploading(uploading: boolean) {
    this.uploading = uploading;
  }

  setUploadProgress(uploadProgress: number) {
    this.uploadProgress = uploadProgress;
  }

  setFileReport(fileData: ReportOUT | undefined | null) {
    this.fileReport = fileData;
  }

  setUploadedFileID(uploadedFileID: string | undefined) {
    this.uploadedFileID = uploadedFileID;
  }

  setReportType(reportType: BGItem | undefined) {
    this.reportType = reportType;
  }

  setUploadError(uploadError: UploadError | undefined) {
    this.uploadError = uploadError;
  }

  setHasLatestConfirmedReport(hasLatestConfirmedReport: boolean) {
    this.hasLatestConfirmedReport = hasLatestConfirmedReport;
  }

  reset() {
    this.setUploadProgress(0);
    this.setFileReport(undefined);
    this.setUploadedFileID(undefined);
    this.setUploadError(undefined);
    this.setHasLatestConfirmedReport(false);
  }

  async uploadCsvFile(file: File) {
    if (!buildingStore.buildingID) return;
    const authHeader = await getLocalAuthHeader();
    this.setUploadError(undefined);
    this.setUploading(true);
    const onUploadProgress = (event: AxiosProgressEvent) => {
      this.setFileReport(undefined);
      const percentage = Math.round(
        (100 * event.loaded) / (event.total as number)
      );
      b6ImporterStore.setUploadProgress(percentage);
    };
    return await operationalEnergyApi
      .reportApiV1RoutersOperationalEnergySubmitFile(
        buildingStore.buildingID,
        file,
        { ...authHeader, onUploadProgress }
      )
      .then((response) => response.data)
      .catch((error) => {
        console.error("error.OperationalEnergySubmitFile", error);
        const response = error.response.data;
        const errorDetail = response.error_code
          ? {
              error: response.error_code,
              sheet: response?.details?.wrong_sheet,
              columns: response?.details?.columns,
            }
          : undefined;
        this.setUploadError(errorDetail);
      });
  }

  async fetchReportData(fileID?: string) {
    if (!buildingStore.buildingID) return;
    const authHeader = await getLocalAuthHeader();
    return await operationalEnergyApi
      .reportApiV1RoutersOperationalEnergyGetLatestReport(
        buildingStore.buildingID,
        fileID,
        authHeader
      )

      .then((response) => {
        this.setFileReport(response.data.report);
        response.data.report?.status === "CONFIRMED" &&
          this.setHasLatestConfirmedReport(true);
        return response.data.report?.status === "CONFIRMED";
      })
      .catch((error) =>
        console.error("error.OperationalEnergyGetLatestReport", error)
      )
      .finally(() => this.setUploading(false));
  }

  async saveFile() {
    if (!buildingStore.buildingID || !this.uploadedFileID) return;
    const authHeader = await getLocalAuthHeader();
    return await operationalEnergyApi
      .reportApiV1RoutersOperationalEnergyConfirmReport(
        this.uploadedFileID,
        authHeader
      )
      .then((response) => response.data)
      .catch((error) =>
        console.error("error.OperationalEnergyConfirmReport", error)
      );
  }

  constructor() {
    makeAutoObservable(this);
  }

  static instance: B6Importer;

  static getInstance(): B6Importer {
    if (!B6Importer.instance) {
      B6Importer.instance = new B6Importer();
    }
    return B6Importer.instance;
  }
}

export const b6ImporterStore = B6Importer.getInstance();
