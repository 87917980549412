import React from "react";
import { Cell } from "@tanstack/react-table";
import useWindowOverflow from "hooks/useWindowOverflow";
import { ContextMenuState } from "components/Table/ContextMenu/useContextMenu";
import { IconFilter } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import useTableMenus from "./useTableMenus";
import { dynamicMEStore } from "store/Mapping/DMEStore";
import Tooltip from "components/Tooltip";

export interface ContextMenuItem {
  label: string;
  icon?: React.ReactNode;
  onClick: (cell?: Cell<unknown, unknown>) => void;
  hidden?: boolean;
  disabled?: boolean;
}

export interface ContextMenuOption {
  title?: string;
  options: ContextMenuItem[];
}

const ContextMenu = ({
  info,
  onClose,
}: {
  info?: ContextMenuState | null;
  onClose?: () => void;
}) => {
  const { getTopPos, getLeftPos, containerRef } = useWindowOverflow();
  const { t } = useTranslation();
  const cell = info?.cell;
  const columnKey = cell?.column.id;

  React.useEffect(() => {
    dynamicMEStore.setCurrentRow(info?.row);
  }, [info?.row?.id]);

  const {
    addFilterRule,
    isByCellValueFiltered,
    propertyMenu,
    getPropertyName,
    truncateText,
    objectMenu,
    groupMenu,
  } = useTableMenus(info?.cell?.column, info?.row);

  const getCellValue = () => {
    const text = (cell?.getValue() as string) ?? "";
    return truncateText(text);
  };

  const menuItems = [
    ...(cell?.getValue()
      ? [
          {
            title: t("mappingEditor.value"),
            options: [
              {
                label: t("mappingEditor.filterByPropertyValue", {
                  propertyName: getPropertyName(),
                  value: getCellValue(),
                }),
                icon: <IconFilter width={19} strokeWidth={2.5} />,
                onClick: () => addFilterRule(String(cell?.getValue())),
                disabled: isByCellValueFiltered(String(cell?.getValue())),
              },
            ],
          },
        ]
      : []),
    ...propertyMenu(),
    ...(!dynamicMEStore.selectedGroupByItems.length ? [objectMenu()] : []),
    ...(dynamicMEStore.selectedGroupByItems.length ? [groupMenu()] : []),
  ];

  if (["object_count", "mappingCell"].includes(String(columnKey))) return;
  return (
    <div
      className="absolute bg-white border border-gray-300 z-10 rounded-md text-xs whitespace-nowrap"
      style={{
        top: getTopPos(info?.y, 25),
        left: getLeftPos(info?.x, 20),
      }}
      ref={containerRef}
    >
      <ul className="list-none">
        {menuItems?.map(
          (item: ContextMenuOption, index: React.Key | null | undefined) => (
            <div key={`0_${index}`}>
              <div
                className={clsx(
                  "bg-gray-50 text-gray-500 px-4 py-1 border-b rounded-t-md",
                  index !== 0 && "border-t"
                )}
              >
                {item.title}
              </div>
              {item.options.map((rec, index) => (
                <li
                  key={index}
                  className={clsx(
                    "cursor-pointer px-4 py-2 h-9 flex items-center",
                    {
                      hidden: rec.hidden,
                      "opacity-30 !cursor-not-allowed": rec.disabled,
                      "hover:bg-indigo-50 hover:text-indigo-700": !rec.disabled,
                    }
                  )}
                  onClick={() => {
                    if (rec.disabled) return;
                    rec.onClick(info?.cell);
                    onClose?.();
                  }}
                >
                  {rec?.icon ? (
                    <span className="mr-1.5">{rec.icon}</span>
                  ) : null}
                  {rec.disabled ? (
                    <Tooltip
                      content={t("mappingEditor.filterAlreadyAdded")}
                      className="!cursor-not-allowed"
                    >
                      {rec.label}
                    </Tooltip>
                  ) : (
                    rec.label
                  )}
                </li>
              ))}
            </div>
          )
        )}
      </ul>
    </div>
  );
};

export default ContextMenu;
