import React, { Fragment } from "react";
import { DropdownOption, DropdownWithSearchProps } from ".";
import { useTranslation } from "react-i18next";
import useWindowOverflow from "hooks/useWindowOverflow";
import { slugify } from "utils";
import OptionItem from "./OptionItem";
import { IconChevronLeft, IconSearch } from "@tabler/icons-react";
import { Combobox, Transition } from "@headlessui/react";
import clsx from "clsx";
import NotFoundImg from "assets/images/icons/not-found.svg";
import ReactDOM from "react-dom";
import DebouncedInput from "components/DebounceInput";

interface OptionsMenu<T> extends DropdownWithSearchProps<T> {
  cursorPosition: { x: number; y: number };
  inputRef: React.RefObject<HTMLDivElement>;
  selectedItems: T[];
  setSelectedItems: React.Dispatch<React.SetStateAction<T[]>>;
  closeMenu?: () => void;
}

function OptionsMenu<Item extends DropdownOption>(props: OptionsMenu<Item>) {
  const { t, i18n } = useTranslation();
  const isEng = i18n.language === "en";
  const { containerRef, getLeftPos } = useWindowOverflow();
  const [selectedCategory, setSelectedCategory] = React.useState<Item[]>([]);
  const [query, setQuery] = React.useState("");
  const wrapperEl = document.getElementById(props.wrapperID as string);

  const filteredItems: Item[] =
    query === "" ? props?.items ?? [] : filterWithQuery();

  function checkQueryExists(item: Item, filteredList: Item[]) {
    (item as DropdownOption).children?.map((rec) => {
      if (rec?.children?.length) {
        checkQueryExists(rec as Item, filteredList);
      } else {
        const foundedItem = slugify(
          rec[props.displayKey as keyof DropdownOption] as string
        ).includes(slugify(query));
        foundedItem && filteredList.push(rec as Item);
      }
    });
  }

  function filterWithQuery() {
    const filteredList: Item[] = [];
    props?.items?.map((item) => {
      checkQueryExists(item, filteredList);
    });
    return filteredList;
  }

  const handleOnSelect = (item: Item) => {
    if ((item as DropdownOption).children?.length) {
      const selected = [...selectedCategory, item];
      setSelectedCategory(selected);
    } else {
      const selected = props.selectedItems.find((rec) => rec.id === item.id)
        ? props.selectedItems.filter(
            (rec) => (rec as DropdownOption).id != (item as DropdownOption).id
          )
        : props.singleSelect
        ? [item]
        : [...props.selectedItems, item];
      props.setSelectedItems(selected);
      props.handleSelect?.(selected);
      props.closeMenu?.();
    }
  };

  function emptyState() {
    return (
      <div className="flex justify-center">
        <div className="w-[300px] flex flex-col -z-10 py-4 items-center justify-center">
          <img alt="not-found" src={NotFoundImg} className="pt-2" />
          <span className="font-normal text-sm text-gray-500 p-4 text-center whitespace-normal">
            {t("commons.onDropDownNoSearchResult")}
          </span>
        </div>
      </div>
    );
  }

  const onClickBack = () => {
    selectedCategory.splice(-1);
    setSelectedCategory([...selectedCategory]);
  };

  function findFilteredChildren(children: DropdownOption[] | undefined) {
    const filteredChildren: DropdownOption[] = [];
    children?.map((rec) => {
      const filtered =
        rec.children?.filter((item) => filteredItems.includes(item as Item)) ??
        [];
      filteredChildren.push(...filtered);
    });
    return filteredChildren;
  }

  function findParent(
    listData: DropdownOption[] | undefined,
    targetId: string | number | undefined
  ): Item | null | undefined {
    if (!listData) return null;
    for (const item of listData) {
      if (item.children) {
        for (const child of item.children) {
          if (child.id === targetId) {
            return item as Item;
          }
          const foundedItem = findParent(child.children, targetId);
          if (foundedItem) {
            return child as Item; // The parent of the third level item
          }
        }
      } else if (item.id === targetId) {
        return item as Item; // Found the item
      }
    }
  }

  function showItems() {
    const childrenList = (selectedCategory.slice(-1)[0] as DropdownOption)
      ?.children;

    if (query) {
      return props?.items?.map((parent) => {
        const filteredChildren = findFilteredChildren(parent.children);
        return (
          <Fragment key={parent.id}>
            {filteredChildren.length ? (
              <div className="bg-gray-50 py-1 px-4 text-gray-500 text-xs font-medium border-t border-b border-gray-300">
                {parent[props.displayKey] as unknown as string}{" "}
              </div>
            ) : null}
            {filteredChildren?.map((item, index) => (
              <OptionItem
                key={item.id}
                item={item as Item}
                index={index}
                handleOnClick={handleOnSelect}
                displayKey={props.displayKey}
                detailKey={props.detailKey}
                details={findParent(props?.items as DropdownOption[], item.id)}
                query={query}
                selected={Boolean(
                  props.selectedItems.find((rec) => rec.id === item.id)
                )}
                hasChildren={Boolean(item?.children?.length)}
              />
            ))}
          </Fragment>
        );
      });
    }

    const itemsList =
      selectedCategory.length && query === "" ? childrenList : filteredItems;

    return itemsList?.map((item, i) => (
      <OptionItem
        key={i}
        item={item as Item}
        index={i}
        handleOnClick={handleOnSelect}
        displayKey={props.displayKey}
        query={query}
        selected={Boolean(
          props.selectedItems.find((rec) => rec.id === item.id)
        )}
        hasChildren={Boolean(item?.children?.length)}
      />
    ));
  }

  function searchField() {
    return (
      <div className="sticky top-0 p-4 z-10 bg-white">
        <div
          className={clsx(
            "bg-white border-gray-300 border rounded-md",
            "flex justify-between px-2 items-center text-gray-900"
          )}
        >
          <DebouncedInput
            className={clsx(
              "w-full pl-0 placeholder:text-gray-500 text-sm leading-5 ring-0 outline-none border-none focus:ring-0 rounded-md",
              props.searchClassName
            )}
            onChange={(value) => {
              setQuery(String(value));
            }}
            value={String(props.value ?? "")}
            placeholder={props.placeholder}
            icon={<></>}
          />
          <IconSearch width={24} className="text-gray-500" />
        </div>
      </div>
    );
  }

  function optionListItems() {
    if (!props.items) return skeleton();
    return (
      <>
        {selectedCategory.length && !query ? (
          <div
            className="text-gray-500 flex items-center pl-2 pr-4 py-[6px] border-y border-gray-300 cursor-pointer bg-gray-50 group/menu relative"
            onClick={onClickBack}
          >
            <IconChevronLeft
              size={24}
              className="group-hover/menu:text-indigo-700"
            />
            <div className="text-center text-sm font-semibold w-full">
              {isEng
                ? selectedCategory?.slice(-1)[0]?.name
                : selectedCategory?.slice(-1)[0]?.name_de}
            </div>
          </div>
        ) : null}
        {filteredItems?.length === 0 && query !== ""
          ? emptyState()
          : showItems()}
      </>
    );
  }

  function calcTopPosition() {
    return Math.round(
      Number(props.inputRef.current?.getBoundingClientRect().top) -
        Number(wrapperEl?.getBoundingClientRect().y) +
        Number(props.inputRef.current?.getBoundingClientRect().height)
    );
  }

  const menuStyle = wrapperEl
    ? {
        top: calcTopPosition(),
        left: Number(props.leftOffset),
      }
    : {
        left: props.fixedPos
          ? "inherit"
          : getLeftPos(props.cursorPosition.x, 20),
      };

  function menuContent() {
    return (
      <Transition
        as={"div"}
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        style={menuStyle}
        ref={containerRef}
        className={clsx(
          "absolute z-10 my-1 w-96",
          "max-h-[396px] overflow-auto rounded-md bg-white text-base !p-0 !max-w-fit",
          "shadow-lg border border-gray-300 min-w-auto w-auto max-w-md focus:outline-none sm:text-sm",
          props.optionsClassName,
          { "cursor-not-allowed": props.disabled }
        )}
      >
        <Combobox.Options>
          {!props.labelWithSearch && !props.hideOptionsSearch
            ? searchField()
            : null}
          {optionListItems()}
        </Combobox.Options>{" "}
      </Transition>
    );
  }

  return wrapperEl
    ? ReactDOM.createPortal(menuContent(), wrapperEl)
    : menuContent();
}

export default OptionsMenu;

function skeleton() {
  return (
    <div className="animate-pulse px-3 pb-3">
      <div className="h-8 w-full bg-gray-200 rounded dark:bg-gray-700 mb-0.5"></div>
      <div className="h-8 w-full bg-gray-200 rounded dark:bg-gray-700 mb-0.5"></div>
    </div>
  );
}
