import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useInterval } from "hooks/useInterval";
import Heading from "components/Heading";
import Button from "components/Button";
import { UploadIcon } from "@heroicons/react/solid";
import SearchSortTable from "components/Table";
import UploadData from "features/Building/BuildingMaterialResources/UploadData";
import CreateMaterialResourcesTableColumns from "./CreateIFCTableColumns";
import { ColumnDef, SortingState } from "@tanstack/react-table";
import { ModalQualityPopup } from "./ModelQualityPopup";
import { observer } from "mobx-react-lite";
import { skeleton } from "utils";
import { buildingStore } from "store/Building/BuildingStore";
import { userStore } from "store/UserStore";
import Modal from "components/Modal";

const BuildingMaterialResources = observer(() => {
  const { t } = useTranslation();
  const { authHeader } = userStore;
  const [showFileUpload, setShowFileUpload] = useState<boolean>(false);
  const [showUploadsDisabled, setShowUploadsDisabled] =
    useState<boolean>(false);
  const { materialResources } = buildingStore;

  useEffect(() => {
    if (!getSortingState().length)
      saveSortingState([{ id: "Upload date", desc: true }]);
    (async () => await buildingStore.getAllMaterialResources())();
  }, []);

  useInterval(async () => {
    if (!authHeader) return console.error("authHeader.undefined");
    await buildingStore.getAllMaterialResources();
  }, 30000);

  const handleOpenFileUpload = useCallback(() => {
    if (!buildingStore.currentBuilding?.uploads_allowed) {
      setShowUploadsDisabled(true);
    } else {
      setShowFileUpload(true);
    }
  }, []);

  const checkLoadingState = () =>
    materialResources.loading ? (
      skeleton("h-80")
    ) : (
      <div className="border rounded-lg border-gray-300 p-2 bg-white">
        <UploadData open={showFileUpload} setClose={setShowFileUpload} />
      </div>
    );

  const saveSortingState = (value: SortingState) =>
    localStorage.setItem("material_resources_sort", JSON.stringify(value));

  const getSortingState = () =>
    JSON.parse(localStorage.getItem("material_resources_sort") ?? "[]");

  return (
    <div id="IFC-File-list-view" className="p-6">
      <div className="flex justify-between pb-2">
        <Heading size={"title"}>{t("buildingMaterialResources.title")}</Heading>
        {materialResources.items.length > 0 && (
          <Button
            type="primary"
            width="fit-content"
            leadingIcon={<UploadIcon />}
            onClick={handleOpenFileUpload}
            testID="uploaddata_button"
          >
            {t("buildingMaterialResources.uploadData")}
          </Button>
        )}
      </div>
      <div className="pb-4 text-sm text-gray-700">
        {t("buildingMaterialResources.uploadFileMessage")}
      </div>
      <div className="pt-2">
        {materialResources.items.length ? (
          <>
            <ModalQualityPopup />
            <SearchSortTable
              getColumns={
                CreateMaterialResourcesTableColumns as () => ColumnDef<
                  unknown,
                  unknown
                >[]
              }
              data={materialResources.items}
              customContainerStyle="overflow-visible"
              borderedStyle
              scrollDivClassName="mt-3 scrollbar"
              onSortChanged={saveSortingState}
              defaultSort={getSortingState()}
            />
          </>
        ) : (
          checkLoadingState()
        )}
      </div>
      <Modal
        isOpen={showUploadsDisabled}
        setIsOpen={setShowUploadsDisabled}
        closeButton
        title={t("dashboard.max_building_heading")}
        disableOutsideClick
        containerClassName="sm:my-8 sm:max-w-sm sm:w-full md:max-w-xl"
      >
        <div>
          <p className="px-6 pt-6 font-[400] text-[14px]">
            {t("dashboard.fileUploadsDisabled")}
          </p>
          <div className="mt-6 px-6 flex w-full justify-end py-4 bg-gray-50">
            <Button
              className="font-[500] text-[16px]"
              width="fit-content"
              onClick={() => setShowUploadsDisabled(false)}
              type="primary"
            >
              {t("dashboard.btn_i_understand")}
            </Button>
          </div>
        </div>
      </Modal>
      {showFileUpload && (
        <UploadData open={showFileUpload} setClose={setShowFileUpload} modal />
      )}
    </div>
  );
});

export default BuildingMaterialResources;
