import React, { useEffect, useState } from "react";
import MainLayout from "layouts/MainLayout";
import { Outlet, useLocation, useNavigate } from "react-router";
import Tabs from "components/Tabs";
import { useRecoilValue } from "recoil";
import { auditsAtom } from "store/atoms/audits";
import { observer } from "mobx-react-lite";
import { buildingStore } from "store/Building/BuildingStore";
import { useBuildings } from "features/Building/useBuildings";
import BlueprintsLayout from "layouts/BlueprintsLayout";
import useBuildingTabOptions from "hooks/useBuildingTabOptions";
import ActionMenu from "components/ActionMenu";
import clsx from "clsx";
import useFeatureFlag from "hooks/useFeatureFlag";
import {
  IconApps,
  IconChevronDown,
  IconLayoutDashboard,
} from "@tabler/icons-react";
import { useTranslation } from "react-i18next";

const Building = observer(() => {
  const audits = useRecoilValue(auditsAtom);
  const location = useLocation();
  const [currentIndex, setCurrentIndex] = useState(0);
  const { isFeatureVisible } = useFeatureFlag();
  const integrationMenuEnabled = isFeatureVisible("integrations_menu");
  const { refreshOnChangeBuilding } = useBuildings();
  const { options } = useBuildingTabOptions();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (!buildingStore.currentBuilding?.id) return;
    onChangeBuilding();
  }, [buildingStore.currentBuilding?.id]);

  async function onChangeBuilding() {
    buildingStore.resetBuildingMappingStatus();
    await refreshOnChangeBuilding();
  }

  useEffect(() => {
    if (location) {
      const currentPath = location.pathname.split("/")[3];
      const currentItem = options.findIndex((item) =>
        item.path?.includes(currentPath)
      );
      setCurrentIndex(currentItem);
    }
  }, [
    location,
    audits && audits.length > 0,
    buildingStore.buildingMappingStatus.hasReport,
  ]);

  useEffect(() => {
    callOnRedirect();
  }, [location]);

  async function callOnRedirect() {
    /* to update dashboards and calculations status on changing tabs */
    if (!buildingStore.materialResources.loading) {
      await buildingStore.getBuildingMappingStatus();
    }
    await buildingStore.getBuildingCalculationsStatus();
  }

  function integrationMenu() {
    return (
      <ActionMenu
        leadingIcon={<IconApps />}
        items={[
          <div
            className="flex items-center"
            key="eEule"
            onClick={() => navigate("eEule", { replace: true })}
          >
            <div
              className={clsx(
                "bg-gray-100 p-2 mr-4 rounded-md transition group-hover:bg-indigo-500 group-hover:text-white"
              )}
            >
              <IconLayoutDashboard className="min-w-[24px] min-h-[24px]" />
            </div>
            <div>
              <div className="text-gray-900 group-hover:text-indigo-700">
                eEule
              </div>
              <div className="text-gray-600 group-hover:text-indigo-500">
                {t("building.eEuleDescription")}
              </div>
            </div>
          </div>,
        ]}
        icon={<IconChevronDown className="ml-1" />}
        className={clsx("h-[51px]", {
          "border-indigo-600 border-b rounded-b-none":
            location.pathname.includes("eEule"),
        })}
        direction="auto"
        containerClassName="!px-[8px] !py-3 rounded-md border-none shadow-lg"
        itemsClassName="w-[390px]"
        itemClassName="hover:bg-indigo-50 hover:text-indigo-700 group rounded-md"
      >
        <span className="text-sm">{t("building.integration")}</span>
      </ActionMenu>
    );
  }

  return location.pathname.includes("blueprints") ? (
    <BlueprintsLayout>
      <Outlet />
    </BlueprintsLayout>
  ) : location.pathname.includes("mapping-editor") ? (
    <MainLayout noHeader>
      <Outlet />
    </MainLayout>
  ) : (
    <MainLayout>
      <div
        className={clsx(
          "sticky z-10 w-full print:hidden flex bg-white items-center border-b h-[52px]",
          "border-gray-300 text-gray-500 hover:text-gray-700"
        )}
      >
        <Tabs
          vertical={false}
          isLink
          currentIndex={currentIndex}
          options={options}
          listClassName={"h-[52px] px-3 w-fit"}
          containerClassName="border-b-0"
        />
        {integrationMenuEnabled ? integrationMenu() : null}
      </div>
      <Outlet />
    </MainLayout>
  );
});

export default Building;
