import React, { useEffect, useState } from "react";
import {
  XCircleIcon,
  XIcon,
  CheckCircleIcon,
  ExclamationIcon,
} from "@heroicons/react/outline";
import clsx from "clsx";
import { Transition } from "@headlessui/react";
import { IconInfoCircle } from "@tabler/icons-react";

interface IProps {
  type?: "attention" | "warning" | "success" | "info";
  title?: string | React.ReactNode;
  description?: string | React.ReactNode;
  dismiss?: boolean;
  time?: number;
  actionButton?: string;
  actionButtonOnClick?: () => void;
  className?: string;
  children?: React.ReactNode;
  titleClassName?: string;
  iconClassName?: string;
}

export default function Alert({
  type = "attention",
  title,
  description,
  dismiss,
  time,
  actionButton,
  actionButtonOnClick,
  className,
  children,
  titleClassName,
}: IProps) {
  const [showAlert, setShowAlert] = useState(true);

  const dismissAfterTime = (time: number) => {
    setTimeout(() => {
      setShowAlert(false);
    }, time);
  };

  useEffect(() => {
    if (time) {
      dismissAfterTime(time);
    }
  }, []);

  return (
    <Transition
      show={showAlert}
      enter="transition-opacity duration-75"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-300"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div
        className={clsx(
          "rounded-md p-4 flex",
          {
            "bg-yellow-50 border border-yellow-100": type === "attention",
            "bg-green-50 border border-green-100": type === "success",
            "bg-red-50 border border-red-100": type === "warning",
            "bg-indigo-50 border-indigo-100 border": type === "info",
          },
          className
        )}
      >
        <div className="flex-shrink-0">
          <div
            className={clsx("rounded-full p-1", {
              "bg-yellow-100": type === "attention",
              "bg-red-100": type === "warning",
              "bg-green-100": type === "success",
              "bg-indigo-100": type === "info",
            })}
          >
            {type === "attention" && (
              <ExclamationIcon
                className="h-4 w-4 text-yellow-700"
                strokeWidth={3}
                aria-hidden="true"
              />
            )}
            {type === "warning" && (
              <XCircleIcon
                className="h-4 w-4 text-red-700"
                strokeWidth={3}
                aria-hidden="true"
              />
            )}
            {type === "success" && (
              <CheckCircleIcon
                className="h-4 w-4 text-green-700"
                strokeWidth={3}
                aria-hidden="true"
              />
            )}
            {type === "info" && (
              <IconInfoCircle
                width={20}
                height={20}
                stroke={3}
                className="text-indigo-700 bg-indigo-100 p-1 rounded-full font-semibold"
              />
            )}
          </div>
        </div>
        <div className={clsx("ml-3 w-full", titleClassName)}>
          {title ? (
            <h3
              className={clsx("text-sm font-medium", {
                "text-yellow-800": type === "attention",
                "text-red-800": type === "warning",
                "text-green-800": type === "success",
                "text-indigo-700": type === "info",
                "pb-2": description,
              })}
            >
              {title}
            </h3>
          ) : null}
          <div
            className={clsx("text-sm font-normal", {
              "text-yellow-700": type === "attention",
              "text-red-700": type === "warning",
              "text-green-700": type === "success",
              "text-indigo-700": type === "info",
            })}
          >
            {description}
          </div>
          <div
            className={clsx("mt-4 w-full text-sm ", {
              "text-yellow-700": type === "attention",
              "text-red-700": type === "warning",
              "text-green-700": type === "success",
              "text-indigo-700": type === "info",
            })}
          >
            {children}
            <div className="-mx-2 -my-1.5 flex justify-end">
              {actionButton && (
                <button
                  type="button"
                  className={clsx(
                    "px-2 py-1.5 rounded-md text-sm font-medium focus:outline-none",
                    {
                      "text-yellow-700 hover:bg-yellow-100":
                        type === "attention",
                      "text-red-700 hover:bg-red-100": type === "warning",
                      "text-green-800 hover:bg-green-100": type === "success",
                      "text-indigo-800 hover:bg-indigo-100": type === "info",
                    }
                  )}
                  onClick={actionButtonOnClick}
                >
                  {actionButton}
                </button>
              )}
              {dismiss && (
                <button
                  type="button"
                  onClick={() => setShowAlert(false)}
                  className={clsx(
                    "absolute top-0 right-0 mr-6 mt-8 rounded-md text-sm font-medium focus:outline-none",
                    {
                      "text-yellow-700 hover:bg-yellow-100":
                        type === "attention",
                      "text-red-700 hover:bg-red-100": type === "warning",
                      "text-green-800 hover:bg-green-100": type === "success",
                      "text-indigo-700 hover:bg-indigo-50": type === "info",
                    }
                  )}
                >
                  <XIcon className="w-6 h-6" />
                </button>
              )}
            </div>
          </div>
        </div>
      </div>{" "}
    </Transition>
  );
}
