import {
  CertificationOut,
  DetailViewMappingIN,
  DynamicColumnOUT,
  EPDConformityOut,
  MaterialResourceOUT,
  ObjectOperationIN,
  ObjectOperationOUT,
  ObjectUpdateData,
} from "../../api-client/generated/api";
import { OBJECT_COUNT_ID } from "../../features/MappingTools/DynamicMappingEditor/const";
import { Row } from "@tanstack/react-table";
import { makeAutoObservable } from "mobx";
import {
  ColumnSourceEnum,
  ColumnToDisplay,
  FileOperationIN,
  Filtering,
  FilteringRule,
  FilteringRuleENUM,
  ObjectListIN,
  ObjectListOUT,
  OperationType,
  PropertyBrowserTreeNode,
  SingleItem,
  SortDirectionENUM,
  Sorting,
  calculationsApi,
  categoriesAPI,
  dynamicMappingEditorApi,
  fileOperationsApi,
  inventoryEPDsApi,
  inventoryMappingEditorAPI,
  mappingEditorDynamicColumns,
  mappingEditorObjectsApi,
  objectOperationsApi,
  repositoriesApi,
  searchAPI,
} from "api-client";
import { getLocalAuthHeader } from "api-client-local/utils";
import {
  ColumnBrowserOpen as ColumnSettings,
  FilteringOption,
  SortingOption,
  ColumnVisibilityState,
  OpenProductDetailsPopup,
  FileMappingStatusType,
  ObjectDetails,
  LastDraggedObject,
} from "./types";
import { SortProps } from "features/MappingTools/DynamicMappingEditor/Filters/SortByPropertyBrowser";
import { BGItem } from "components/ButtonGroups";
import { FILTER_VIEW_OPTIONS } from "features/MappingTools/DynamicMappingEditor/Filters";
import { FilterRule } from "features/MappingTools/DynamicMappingEditor/Filters/FilterByPropertyBrowser";
import { COLUMNS_GROUPS } from "features/MappingTools/DynamicMappingEditor/DMETableView/useTableColumns";
import { detailViewV2Store } from "./DetailViewStore";
import { AxiosError, AxiosResponse } from "axios";
import { NotificationType } from "components/Toast";

class DMEStore {
  currentRow: Row<unknown> | undefined = undefined;
  selectedRows: SingleItem[] = [];
  selectedRowIndexes: string[] = [];
  selectAllChecked: boolean = false;
  dataFetchLoading: boolean = false;
  data: ObjectListOUT = {
    items: [],
    count: 0,
  };
  openProductDetailsPopup: OpenProductDetailsPopup = { open: false };
  openedObjectDetails: boolean = false;
  selectedGroupByItems: PropertyBrowserTreeNode[] = [];
  selectedFilterByItems: FilteringOption = {
    logical_operator: "AND",
    rules: [],
  };
  selectedSortedByItems: SortProps[] = [];
  dynamicColumns: DynamicColumnOUT[] = [];
  openFilter: boolean = false;
  openSort: boolean = false;
  openGroupBy: boolean = false;
  selectedFilterView: BGItem | undefined = FILTER_VIEW_OPTIONS[0];
  validFilterRules: Filtering | null = null;
  validSortingRules: Sorting[] | null = null;
  validGroupByRules: ColumnToDisplay[] = [];
  showMappingLoading: boolean = false;
  matchForSelectedItems: boolean = false;
  visibleColumnProperties: PropertyBrowserTreeNode[] = [];
  openColumnSettings: ColumnSettings = {
    groupName: "",
    open: false,
    positionX: 0,
    positionY: 0,
  };
  properties: PropertyBrowserTreeNode[] = [];
  columnsVisibilityState: ColumnVisibilityState = {};
  columnsOrder: string[] = [];
  errorFetchingObjects: boolean = false;
  open3DViewer: boolean = false;
  allObjectsIfcIDs: string[] = [];
  openAddColumns: boolean = false;
  selectedGroupsGlobalIDs: string[] = [];
  hoveredGroupsGlobalIDs: string[] = [];
  fileCalculationsStatus: boolean = false;
  openBulkActionsMenu: boolean = false;
  keyPressed: string | null = null;
  selectedStartIndex: number | null = null;
  hoveredRow: Row<unknown> | null = null;
  errorMessage = "";
  fileMappingStatus: FileMappingStatusType | undefined;
  mappingPerformed = false;
  certificationOptions: CertificationOut[] = [];
  conformityOptions: EPDConformityOut[] = [];
  objectDetail: ObjectDetails = { loading: true, data: undefined };
  isConfirmationVisible: boolean = false;
  resolvePromise: ((value: boolean) => void) | null = null;
  lastShiftSelectKey: string | undefined = undefined;
  lastDraggedObject: LastDraggedObject = {
    index: undefined,
    direction: undefined,
  };

  showConfirmation(): Promise<boolean> {
    this.isConfirmationVisible = true;

    return new Promise<boolean>((resolve) => {
      this.resolvePromise = resolve;
    });
  }

  confirmMappingToFile() {
    this.isConfirmationVisible = false;
    this.resolvePromise?.(true);
    this.resolvePromise = null;
  }

  cancelMappingToFile() {
    this.isConfirmationVisible = false;
    this.resolvePromise?.(false);
    this.resolvePromise = null;
  }

  setCurrentRow(currentRow: Row<unknown> | undefined) {
    this.currentRow = currentRow;
  }

  setSelectedRows(selectedRowsIndexes: string[]) {
    this.selectedRowIndexes = selectedRowsIndexes;
    this.selectedRows = selectedRowsIndexes.map((index) => {
      return this.data.items[Number(index)];
    });
    if (
      this.selectedRowIndexes.length &&
      this.selectedRowIndexes.length === this.data.count
    ) {
      this.setSelectAllChecked(true);
    }
  }

  setSelectedStartIndex(selectedStartIndex: number | null) {
    this.selectedStartIndex = selectedStartIndex;
  }

  setHoveredRow(hoveredRow: Row<unknown> | null) {
    this.hoveredRow = hoveredRow;
  }

  addToSelectedRows(row_index: string[]) {
    this.setSelectedRows([...this.selectedRowIndexes, ...row_index]);
  }

  removeFromSelectedRows(row_index: string) {
    const filteredItems = this.selectedRowIndexes.filter(
      (item) => item !== row_index
    );
    this.setSelectedRows(filteredItems);
  }

  setSelectAllChecked(selectAllChecked: boolean) {
    this.selectAllChecked = selectAllChecked;
    if (!selectAllChecked) this.setSelectedStartIndex(null);
  }

  setData(data: ObjectListOUT) {
    this.data = data;
  }

  setDynamicColumns(dynamicColumns: DynamicColumnOUT[]) {
    this.dynamicColumns = dynamicColumns;
  }

  setDataFetchLoading(dataFetchLoading: boolean) {
    this.dataFetchLoading = dataFetchLoading;
  }

  setOpenProductDetailsPopup(openProductDetailsPopup: OpenProductDetailsPopup) {
    this.openProductDetailsPopup = openProductDetailsPopup;
  }

  setOpenedObjectDetails(openedObjectDetails: boolean) {
    this.openedObjectDetails = openedObjectDetails;
  }

  setSelectedGroupByItems(selectedGroupByItems: PropertyBrowserTreeNode[]) {
    this.selectedGroupByItems = selectedGroupByItems;
    this.getGroupingProps();
  }

  getcurrentGroupKeys(groupName: string) {
    const groupingKeys = this.selectedGroupByItems.map(
      (item) => item.column_key
    );
    const filteredItemsKeys = this.visibleColumnProperties
      .filter((item) =>
        groupName === COLUMNS_GROUPS.infoColumns
          ? groupingKeys.indexOf(item.column_key) === -1
          : groupingKeys.indexOf(item.column_key) != -1
      )
      .map((rec) => rec.column_key);
    return filteredItemsKeys;
  }

  setSelectedFilterByItems(selectedFilterByItems: FilteringOption) {
    this.selectedFilterByItems = selectedFilterByItems;
    this.getFilterProps();
  }

  setSelectedSortedByItems(selectedSortedByItems: SortProps[]) {
    this.selectedSortedByItems = selectedSortedByItems;
    this.getSortingProps();
  }

  setOpenFilter(openFilter: boolean) {
    if (openFilter) {
      this.setOpenSort(false);
      this.setOpenGroupBy(false);
      this.openColumnSettings.open = false;
    }
    this.openFilter = openFilter;
  }

  setOpenSort(openSort: boolean) {
    if (openSort) {
      this.setOpenFilter(false);
      this.setOpenGroupBy(false);
      this.openColumnSettings.open = false;
    }
    this.openSort = openSort;
  }

  setOpenGroupBy(openGroupBy: boolean) {
    if (openGroupBy) {
      this.setOpenFilter(false);
      this.setOpenSort(false);
      this.openColumnSettings.open = false;
    }
    this.openGroupBy = openGroupBy;
  }

  setOpenAddColumns(openAddColumns: boolean) {
    this.openAddColumns = openAddColumns;
  }

  setSelectedFilterView(selectedFilterView: BGItem | undefined) {
    this.selectedFilterView = selectedFilterView;
  }

  setShowMappingLoading(showMappingLoading: boolean) {
    this.showMappingLoading = showMappingLoading;
  }

  setMatchForSelectedItems(matchForSelectedItems: boolean) {
    this.matchForSelectedItems = matchForSelectedItems;
  }

  setOpenColumnSettings(openColumnSettings: ColumnSettings) {
    this.openColumnSettings = openColumnSettings;
  }

  setVisibleColumnProperties(properties: PropertyBrowserTreeNode[]) {
    this.visibleColumnProperties = properties;
  }

  setProperties(properties: PropertyBrowserTreeNode[]) {
    this.properties = properties;
  }

  setColumnsVisibilityState(columnsState: ColumnVisibilityState) {
    this.columnsVisibilityState = columnsState;
  }

  setColumnsOrder(columnsOrder: string[]) {
    this.columnsOrder = columnsOrder;
  }

  setErrorFetchingObjects(errorFetchingObjects: boolean) {
    this.errorFetchingObjects = errorFetchingObjects;
  }

  setOpen3DViewer(open3DViewer: boolean) {
    this.open3DViewer = open3DViewer;
  }

  setSelectedGroupsGlobalIDs(selectedGroupsGlobalIDs: string[]) {
    this.selectedGroupsGlobalIDs = selectedGroupsGlobalIDs;
  }

  setHoveredGroupsGlobalIDs(hoveredGroupsGlobalIDs: string[]) {
    this.hoveredGroupsGlobalIDs = hoveredGroupsGlobalIDs;
  }

  setFileCalculationsStatus(fileCalculationsStatus: boolean) {
    this.fileCalculationsStatus = fileCalculationsStatus;
  }

  setOpenBulkActionsMenu(openBulkActionsMenu: boolean) {
    this.openBulkActionsMenu = openBulkActionsMenu;
  }

  setKeyPressed(keyPressed: string | null) {
    this.keyPressed = keyPressed;
  }

  setMappingPerformed(performed: boolean) {
    this.mappingPerformed = performed;
  }

  setFileMappingStatus(fileMappingStatus: FileMappingStatusType) {
    this.fileMappingStatus = fileMappingStatus;
  }

  setErrorMessage(message: string) {
    this.errorMessage = message;
  }

  clearErrorMessage() {
    this.errorMessage = "";
  }

  setCertificationOptions(certificationOptions: CertificationOut[]) {
    this.certificationOptions = certificationOptions;
  }

  setConformityOptions(conformityOptions: EPDConformityOut[]) {
    this.conformityOptions = conformityOptions;
  }

  setObjectDetail(objectDetail: ObjectDetails) {
    this.objectDetail = objectDetail;
  }

  setLastShiftSelectKey(lastShiftSelectKey: string | undefined) {
    this.lastShiftSelectKey = lastShiftSelectKey;
  }

  setLastDraggedObject(lastDraggedObject: LastDraggedObject) {
    this.lastDraggedObject = lastDraggedObject;
  }

  resetData() {
    this.data = {
      items: [],
      count: 0,
    };
    this.properties = [];
    this.allObjectsIfcIDs = [];
    this.selectedFilterByItems = {
      logical_operator: "AND",
      rules: [],
    };
    this.selectedGroupByItems = [];
    this.selectedSortedByItems = [];
    this.validFilterRules = null;
    this.validSortingRules = null;
    this.validGroupByRules = [];
    this.currentRow = undefined;
    this.openedObjectDetails = false;
    this.visibleColumnProperties = [];
    this.dynamicColumns = [];
    this.openFilter = false;
    this.openSort = false;
    this.openColumnSettings.open = false;
    this.selectedFilterView = FILTER_VIEW_OPTIONS[0];
    this.open3DViewer = false;
    this.fileCalculationsStatus = false;
    this.openBulkActionsMenu = false;
    this.selectAllChecked = false;
  }

  get tableColumnsOrder(): string[] {
    return ["select", OBJECT_COUNT_ID, ...this.columnsOrder];
  }

  private updateData(receivedData: ObjectListOUT, update?: boolean) {
    if (receivedData && !receivedData.items) return;
    if (update && receivedData) {
      this.setData(receivedData);
    } else if (!update && receivedData) {
      this.setData({
        items: [...this.data.items, ...receivedData.items],
        count: receivedData.count,
      });
    }
  }

  private validFilter(rule: FilterRule) {
    const noValueNeeded = ["IS_EMPTY", "IS_NOT_EMPTY"].includes(rule.operator);
    return noValueNeeded ? !!rule.property : rule.property && rule.value;
  }

  private getGroupingProps() {
    this.validGroupByRules = this.selectedGroupByItems.map((item) => ({
      column_key: item.column_key,
      column_source: item.column_source as ColumnSourceEnum,
    }));
  }

  private getFilterProps() {
    const rules = this.selectedFilterByItems.rules
      .map((item) => {
        if (this.validFilter(item)) {
          let value =
            item.value && item.value.trim().length > 0 ? item.value : "";
          if (!value) return;
          if (item.property?.is_numeric) {
            value = value.replace(",", ".");
          }
          return {
            column_key: item.property?.column_key as string,
            column_source: item.property?.column_source as ColumnSourceEnum,
            operator: item.operator as FilteringRuleENUM,
            value: value,
            name: item.property?.name,
            name_de: item.property?.name_de,
          };
        }
      })
      .filter((rec) => rec);
    if (rules.length) {
      const isEqual =
        this.filterArraysAreEqual(
          rules as FilteringRule[],
          this.validFilterRules?.rules as FilteringRule[]
        ) &&
        this.validFilterRules?.logical_operator ===
          this.selectedFilterByItems.logical_operator;
      if (!isEqual) {
        this.validFilterRules = {
          logical_operator: this.selectedFilterByItems.logical_operator,
          rules: rules as FilteringRule[],
        };
      }
    } else if (this.validFilterRules) this.validFilterRules = null;
  }

  private sortArraysAreEqual(array1: SortProps[], array2: Sorting[] | null) {
    const selectedKeys = array1.map((item) => item.property?.column_key);
    const validKeys = array2?.map((item) => item.column_key);
    const isEqual =
      validKeys &&
      selectedKeys.every(
        (value, index) =>
          value === validKeys[index] &&
          array2?.[index].direction === array1?.[index].sort
      );
    return isEqual && array1.length === array2?.length;
  }

  private filterArraysAreEqual(
    array1: FilteringRule[],
    array2: FilteringRule[] | null
  ) {
    const selectedKeys = array1.map((item) => item?.column_key);
    const validKeys = array2?.map((item) => item.column_key);
    const isEqual =
      validKeys &&
      selectedKeys.every(
        (value, index) =>
          value === validKeys[index] &&
          array2?.[index].value === array1?.[index].value &&
          array2?.[index].operator === array1?.[index].operator
      );
    return isEqual && array1.length === array2?.length;
  }

  private getSortingProps() {
    const isEqual = this.sortArraysAreEqual(
      this.selectedSortedByItems,
      this.validSortingRules
    );
    if (isEqual) return;

    const validatedRows = this.selectedSortedByItems
      .map((item) => {
        if (item.property)
          return {
            column_key: item.property?.column_key,
            column_source: item.property?.column_source as ColumnSourceEnum,
            direction: item.sort as SortDirectionENUM,
          };
      })
      .filter((rec) => rec);
    if (validatedRows.length) {
      this.validSortingRules = validatedRows as SortingOption[];
    } else if (this.validSortingRules) this.validSortingRules = null;
  }

  async fetchObjectsList(start: number, update?: boolean, ifc_id?: string) {
    if (!ifc_id || this.dataFetchLoading) return;
    const authHeader = await getLocalAuthHeader();
    this.setDataFetchLoading(true);
    this.setErrorFetchingObjects(false);
    const props = {
      columns_to_display: this.getColumnsProps(),
    } as ObjectListIN;
    if (this.validFilterRules) {
      props.filtering = this.validFilterRules;
    }
    if (this.validSortingRules) {
      props.sorting = this.validSortingRules;
    }
    if (this.validGroupByRules.length) {
      props.grouping = this.validGroupByRules;
    }
    const page = Math.round(start / 100) + 1;
    await mappingEditorObjectsApi
      .mappingEditorApiV1RoutersObjectsListObjectsByFileId(
        ifc_id,
        props,
        page,
        100,
        authHeader
      )
      .then((response) => this.updateData(response.data, update))
      .catch((error) => {
        this.setErrorFetchingObjects(true);
        console.error("error.ObjectsByFileId", error);
      });
    this.updateSelectedRows();
    await this.getAllObjectsIDs(ifc_id, props.filtering);
    this.setDataFetchLoading(false);
  }

  updateSelectedRows() {
    if (this.data.items.length < this.selectedRowIndexes.length) {
      this.selectedRowIndexes.splice(this.data.items.length);
      this.setSelectedRows(this.selectedRowIndexes);
    }
  }

  private getColumnsProps() {
    return this.visibleColumnProperties.map((item) => ({
      column_key: item.column_key,
      column_source: item.column_source,
    })) as ColumnToDisplay[];
  }

  async fetchColumns(ifc_id?: string): Promise<DynamicColumnOUT[] | void> {
    const authHeader = await getLocalAuthHeader();
    const result = await mappingEditorDynamicColumns
      .mappingEditorApiV1RoutersDynamicColumnsGetDynamicColumns(
        ifc_id,
        authHeader
      )
      .then((response) => {
        this.setDynamicColumns(response.data);
        return response.data;
      })
      .catch((error) => console.error("error.GetDynamicColumns", error));
    return result;
  }

  private readonly onDoneMatching = async (file_id: string | undefined) => {
    await this.getObjectsMappingStatus(file_id);
    this.setShowMappingLoading(false);
    this.getFileCalculationsStatus(file_id);
  };

  private updateObjects(updatedObjects: SingleItem[]) {
    const _items = [...this.data.items];
    updatedObjects.forEach((rec) => {
      const foundedIndex = _items.findIndex((item) => item.id === rec?.id);
      _items[foundedIndex] = { ...rec };
    });
    this.setData({
      count: this.data.count,
      items: _items,
    });
  }

  private updateGroups(updatedGroups: SingleItem[]) {
    const groupKeys = this.validGroupByRules.map((item) => item.column_key);
    const _items = [...this.data.items];
    updatedGroups.forEach((updatedItem) => {
      const foundedIndex = _items.findIndex((item) =>
        isTheSameItem(item, updatedItem)
      );
      _items[foundedIndex] = { ...updatedItem };
    });
    this.setData({
      count: this.data.count,
      items: _items,
    });

    function isTheSameItem(item: SingleItem, updatedItem: SingleItem) {
      return (
        groupKeys
          .map(
            (key) =>
              item.values[key as keyof typeof item.values] ===
              updatedItem.values[key as keyof typeof item.values]
          )
          .filter(Boolean).length === groupKeys.length
      );
    }
  }

  filterPropertiesByAvailableColumns() {
    return this.properties
      .map((parent) => {
        const updatedParent = { ...parent };
        updatedParent.children = parent.children
          ?.map((child) => {
            const updatedChild = { ...child };
            const filteredChild = child.children
              ?.map((grandchild) => {
                if (
                  this.visibleColumnProperties.find(
                    (item) => item.column_key === grandchild.column_key
                  )
                ) {
                  return grandchild;
                } else {
                  return null;
                }
              })
              .filter(Boolean) as PropertyBrowserTreeNode[];
            updatedChild.children = filteredChild;
            return updatedChild;
          })
          .filter((item) => item.children?.length);

        return updatedParent;
      })
      .filter((item) => item?.children?.length);
  }

  filterNotSelectedItems(
    data: PropertyBrowserTreeNode[],
    selectedItems: PropertyBrowserTreeNode[]
  ) {
    return data
      .map((parent) => {
        const updatedParent = { ...parent };
        updatedParent.children = parent.children
          ?.map((child) => {
            const updatedChild = { ...child };
            const filteredChild = child.children
              ?.map((grandchild) => {
                if (
                  selectedItems?.find(
                    (item) => item?.column_key === grandchild.column_key
                  ) === undefined
                ) {
                  return grandchild;
                } else {
                  return null;
                }
              })
              .filter(Boolean) as PropertyBrowserTreeNode[];
            updatedChild.children = filteredChild;
            return updatedChild;
          })
          .filter((item) => item.children?.length);

        return updatedParent;
      })
      .filter((item) => item?.children?.length);
  }

  setDefaultVisibleColumnProperties(visibleColumnNames: string[] | null) {
    const allProperties = this.getAllPropertiesFlat();
    const visibleColumns = allProperties.filter((child) =>
      visibleColumnNames?.includes(child.column_key)
    );
    this.setVisibleColumnProperties(visibleColumns);
  }

  getDBSavedDefaultColumnsKeys() {
    const defaultColumnsKeys = this.dynamicColumns
      .filter((item) => item.default)
      .map((item) => item.key_name);
    return defaultColumnsKeys;
  }

  get interactionDisabled(): boolean {
    return (
      this.openFilter ||
      this.openSort ||
      this.openColumnSettings.open ||
      this.openAddColumns ||
      this.openBulkActionsMenu
    );
  }

  sortPropertiesRecursively(
    objects: PropertyBrowserTreeNode[],
    key: keyof PropertyBrowserTreeNode,
    ascending: boolean = true
  ): any[] {
    return objects
      .map((obj) => ({
        ...obj,
        children:
          obj.children && obj.children.length > 0
            ? this.sortPropertiesRecursively(obj.children, key, ascending)
            : [],
      }))
      .sort((a, b) => {
        const valueA = a[key]?.toString().toLowerCase() ?? "";
        const valueB = b[key]?.toString().toLowerCase() ?? "";
        if (valueA < valueB) return ascending ? -1 : 1;
        if (valueA > valueB) return ascending ? 1 : -1;
        return 0;
      });
  }

  async getProperties(
    file_id: string | undefined,
    sortKey: keyof PropertyBrowserTreeNode
  ) {
    if (!file_id) return;
    const authHeader = await getLocalAuthHeader();
    return await dynamicMappingEditorApi
      .mappingEditorApiV1RoutersIndexFilterPropertyBrowserFull(
        file_id,
        authHeader
      )
      .then((response) => {
        const sortedData = this.sortPropertiesRecursively(
          response.data,
          sortKey
        );
        this.setProperties(sortedData);
      })
      .catch((error) =>
        console.error("error.FilterPropertyBrowserFull", error)
      );
  }

  async runFileOperation(
    toast: (message: string, type: NotificationType) => void,
    file_id: string | undefined,
    operation_type: OperationType,
    product_id?: string | null,
    object_update_data?: ObjectUpdateData | null,
    confirm_all?: boolean
  ) {
    if (!file_id) return;
    const authHeader = await getLocalAuthHeader();
    this.setShowMappingLoading(true);
    const props: FileOperationIN = {
      operation_type: operation_type,
      columns_to_display: this.getColumnsProps(),
      confirm_all: Boolean(confirm_all),
    };
    if (this.validFilterRules) props.filtering = this.validFilterRules;
    if (this.validSortingRules) props.sorting = this.validSortingRules;
    if (product_id) props.product_uuid = product_id;
    if (object_update_data) props.object_update_data = object_update_data;
    return await fileOperationsApi
      .mappingEditorApiV1RoutersFileOperationsRunFileOperations(
        file_id,
        props,
        0,
        100,
        authHeader
      )
      .then((response) => this.updateObjects(response.data.items))
      .catch((error: AxiosError) => {
        console.error(error, "error.FileOperationsRunFileOperations");
        toast(error.message, "warning");
      })
      .finally(() => this.onDoneMatching(file_id));
  }

  createCurrentRowGroupSelection(current_object: SingleItem) {
    return [
      {
        logical_operator: "AND",
        rules: this.validGroupByRules.map((item) => ({
          column_key: item.column_key,
          column_source: item.column_source,
          operator: "IS" as FilteringRuleENUM,
          value: current_object[item.column_key as keyof SingleItem],
        })),
      },
    ] as Filtering[];
  }

  createSelectedRowsGroupSelection() {
    const result = this.selectedRows.map((row) => ({
      logical_operator: "AND",
      rules: this.validGroupByRules.map((item) => ({
        column_key: item.column_key,
        column_source: item.column_source,
        operator: "IS" as FilteringRuleENUM,
        value: row.values[item.column_key as keyof typeof row.values],
      })),
    }));
    return result as Filtering[];
  }

  async runSelectedObjectOperations(
    toast: (message: string, type: NotificationType) => void,
    file_id: string | undefined,
    operation_type: OperationType,
    produc_id?: string | null,
    current_object?: SingleItem | null,
    object_update_data?: ObjectUpdateData | null
  ) {
    if (!file_id) return;
    this.setShowMappingLoading(true);
    const authHeader = await getLocalAuthHeader();

    const props: ObjectOperationIN = {
      operation_type: operation_type,
      columns_to_display: this.getColumnsProps(),
      product_uuid: produc_id,
      filtering: this.validFilterRules,
      sorting: this.validSortingRules ?? [],
      grouping: this.validGroupByRules,
    };

    if (this.validGroupByRules.length) {
      props.group_selection = current_object
        ? this.createCurrentRowGroupSelection(current_object)
        : this.createSelectedRowsGroupSelection();
    } else {
      props.object_ids = current_object
        ? [String(current_object.id)]
        : this.selectedRows.map((item) => String(item?.id));
    }
    if (object_update_data) props.object_update_data = object_update_data;

    return await objectOperationsApi
      .mappingEditorApiV1RoutersObjectOperationsRunFileObjectOperations(
        file_id,
        props,
        authHeader
      )
      .then((response) =>
        this.validGroupByRules.length
          ? this.checkGroupsForUpdate(file_id, response)
          : this.updateObjects(response.data.items)
      )
      .catch((error: AxiosError) => {
        console.error(error, "ObjectOperationsRunFileObjectOperations");
        toast(error.message, "warning");
      })
      .finally(() => this.onDoneMatching(file_id));
  }

  private checkGroupsForUpdate(
    file_id: string,
    response: AxiosResponse<ObjectOperationOUT>
  ): void | PromiseLike<void> {
    return this.validGroupByRules.find(
      (rec) => rec.column_key === "match_calculations.product_name"
    )
      ? this.fetchObjectsList(0, true, file_id)
      : this.updateGroups(response.data.items);
  }

  async getAllObjectsIDs(
    file_id: string | undefined,
    filtering?: Filtering | null
  ) {
    const authHeader = await getLocalAuthHeader();
    if (!file_id) return;
    await mappingEditorObjectsApi
      .mappingEditorApiV1RoutersObjectsGetAllObjects(
        file_id,
        { filtering: filtering },
        authHeader
      )
      .then(
        (response) =>
          (this.allObjectsIfcIDs = response.data.map(
            (item) => item.ifc_global_id as string
          ))
      )
      .catch((error) => console.error(error, "error.GetAllObjects"));
  }

  getAllPropertiesFlat() {
    return this.properties
      .map((item) => item.children?.map((rec) => rec.children))
      .map((item) => item?.flat())
      .flat()
      .filter((item) => item?.column_key !== "") as PropertyBrowserTreeNode[];
  }

  removeItemFromSortList(id: string) {
    const filteredItems = this.selectedSortedByItems.filter(
      (item) => item.property?.id !== id
    );
    this.setSelectedSortedByItems(filteredItems);
  }

  removeItemFromGoupingList(id: string) {
    const filteredItems = this.selectedGroupByItems.filter(
      (item) => item?.id !== id
    );
    this.setSelectedGroupByItems(filteredItems);
  }

  async fetchColumnSuggestions(
    file_id: string | undefined,
    property: PropertyBrowserTreeNode
  ) {
    if (!file_id || !property) return;
    const authHeader = await getLocalAuthHeader();
    return await dynamicMappingEditorApi
      .mappingEditorApiV1RoutersIndexFilterRetrieveColumnSuggestions(
        file_id,
        {
          column_key: property.column_key,
          column_source: property.column_source as ColumnSourceEnum,
        },
        authHeader
      )
      .then((response) => response.data)
      .catch((error) =>
        console.error("error.RetrieveColumnSuggestions", error)
      );
  }

  updateFilterRules(rules: FilterRule[]) {
    this.setSelectedFilterByItems({
      ...this.selectedFilterByItems,
      rules: [...rules],
    });
  }

  removeGroupingRule(id: string) {
    const filteredList = this.selectedGroupByItems.filter(
      (item) => item.id !== id
    );
    this.setSelectedGroupByItems(filteredList);
    this.reOrderGroupingColumns();
  }

  removeAllFilteringRules() {
    this.updateFilterRules([]);
    this.setOpenFilter(false);
  }

  removeAllSortingRules() {
    this.setSelectedSortedByItems([]);
    this.setOpenSort(false);
  }

  removeAllGroupingRules() {
    this.setSelectedGroupByItems([]);
    this.setOpenGroupBy(false);
  }

  reOrderGroupingColumns() {
    const groupingKeys = this.selectedGroupByItems.map((rec) => rec.column_key);
    const informationColumns = this.visibleColumnProperties?.filter(
      (item) => !groupingKeys.includes(item.column_key)
    );
    const newOrder = [...this.selectedGroupByItems, ...informationColumns];
    this.setVisibleColumnProperties(newOrder);
  }

  async detailViewMapping(product_id: string | null) {
    const currentRowObjectID = this.getCurrentRowObject()?.id;
    if (!currentRowObjectID) return;
    const authHeader = await getLocalAuthHeader();
    await objectOperationsApi
      .mappingEditorApiV1RoutersObjectOperationsDetailViewMapping(
        currentRowObjectID,
        {
          columns_to_display: this.getColumnsProps(),
          product_uuid: product_id,
          operation_type: product_id ? "manual_match" : "undo_match",
          detail_view_report_keys: {
            info: true,
            reports: true,
            ifc_properties: false,
          },
        } as DetailViewMappingIN,
        authHeader
      )
      .then((response) => {
        this.updateObjects([response.data.row_data]);
        detailViewV2Store.setInfoTileData(response.data.object_details.info);
        detailViewV2Store.setReportsData(
          product_id ? response.data.object_details.reports : null
        );
      })
      .catch((error) =>
        console.error(error, "ObjectOperationsDetailViewMapping")
      );
  }

  getCurrentRowObject() {
    const objectID = (this.currentRow?.original as SingleItem)?.id;
    if (objectID) return this.data.items.find((item) => item.id === objectID);
  }

  async fetchGroupsGlobalIfcIds(
    file_id: string | undefined,
    current_object?: SingleItem,
    hovered?: boolean
  ) {
    if (!file_id) return;
    const authHeader = await getLocalAuthHeader();
    return await mappingEditorObjectsApi
      .mappingEditorApiV1RoutersObjectsFetchGlobalIfcIdsFromGroups(
        file_id,
        {
          group_selection: current_object
            ? this.createCurrentRowGroupSelection(current_object)
            : this.createSelectedRowsGroupSelection(),
          filtering: this.validFilterRules,
        },
        authHeader
      )
      .then((response) => {
        const data = response.data;
        const ifcIDs = data
          .map((item) => item.ifc_global_id as string)
          .filter(Boolean);
        if (hovered) {
          this.setHoveredGroupsGlobalIDs(ifcIDs);
        } else {
          this.setSelectedGroupsGlobalIDs(ifcIDs);
        }
      })
      .catch((error) =>
        console.error(error, "error.ObjectsFetchGlobalIfcIdsFromGroups")
      );
  }

  hasGrouping() {
    return this.validGroupByRules.length > 0;
  }

  async getFileCalculationsStatus(file_id: string | undefined) {
    if (!file_id) return;
    const authHeader = await getLocalAuthHeader();
    return await calculationsApi
      .concularApiV1RoutersCalculationsGetCalculationsStatus(
        "file",
        file_id,
        authHeader
      )
      .then((response) =>
        this.setFileCalculationsStatus(response.data.status === "IN_PROGRESS")
      );
  }

  findPropertyByKey(column_key: string | undefined) {
    return this.getAllPropertiesFlat().find(
      (rec) => rec.column_key === column_key
    );
  }

  async getObjectsMappingStatus(ifc_id: string | undefined) {
    if (!ifc_id) return;
    this.clearErrorMessage();
    const authHeader = await getLocalAuthHeader();
    this.setFileMappingStatus(undefined);
    await inventoryMappingEditorAPI
      .inventoryApiV1RoutersMappingEditorGetFileStatus(ifc_id, authHeader)
      .then((response: AxiosResponse) => {
        this.setFileMappingStatus(response.data);
      })
      .catch((error) => {
        console.error(error, "error.getObjectsMappingStatus");
        this.setErrorMessage((error as Error).message);
      });
  }

  async getCategories(categorySystem: string) {
    const authHeader = await getLocalAuthHeader();
    return await categoriesAPI
      .caApiV1RoutersCategoryCategories(categorySystem, authHeader)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log("getCategories.error", error);
      });
  }

  async fetchCertificationData() {
    if (this.certificationOptions.length) return;
    const authHeader = await getLocalAuthHeader();
    return await inventoryEPDsApi
      .inventoryApiV1RoutersEpdCertificationData(authHeader)
      .then((response) => {
        this.setCertificationOptions(response.data);
        return response.data;
      })
      .catch((error) =>
        console.error("Failed to fetchCertificationData:", error)
      );
  }

  async fetchConformities() {
    if (this.conformityOptions.length) return;
    const authHeader = await getLocalAuthHeader();
    await inventoryEPDsApi
      .inventoryApiV1RoutersEpdEpdConformityData(authHeader)
      .then((response) => this.setConformityOptions(response.data))
      .catch((error) => console.log("getConformities.error", error));
  }

  async getMaterials() {
    const authHeader = await getLocalAuthHeader();
    return await searchAPI
      .inventoryApiV1RoutersSearchEngineMaterials(authHeader)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log("getMaterials.error", error);
      });
  }

  async getManufacturers() {
    const authHeader = await getLocalAuthHeader();
    return await repositoriesApi
      .caApiV1RoutersRepositoryGetManufacturers(authHeader)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log("getManufacturers.error", error);
      });
  }

  async updateFileMappingStatus(ifcFile: MaterialResourceOUT | undefined) {
    ifcFile?.id
      ? await this.getObjectsMappingStatus(ifcFile?.id)
      : this.setFileMappingStatus(undefined);
  }

  constructor() {
    makeAutoObservable(this);
    // set default sorting
    this.getSortingProps();
  }

  static instance: DMEStore;

  static getInstance(): DMEStore {
    if (!DMEStore.instance) {
      DMEStore.instance = new DMEStore();
    }
    return DMEStore.instance;
  }
}

export const dynamicMEStore = DMEStore.getInstance();
