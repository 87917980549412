import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import Retool from ".";
import MainLayout from "layouts/MainLayout";
import { useParams } from "react-router";
import { buildingStore } from "store/Building/BuildingStore";

const PortfolioDashboard = () => {
  const reportID = "235f801c-dcd1-11ef-a900-8feb47528008";
  const { organisation_id } = useParams();

  const embeddedData = {
    organisation_id: organisation_id,
  };

  useEffect(() => {
    // deselect building from the menu
    buildingStore.setCurrentBuilding(undefined);
  }, [organisation_id]);

  return (
    <MainLayout headerTitle="Portfolio Dashboard">
      <Retool url={reportID} embeddedData={embeddedData} />
    </MainLayout>
  );
};

export default observer(PortfolioDashboard);
