import React from "react";
import { useParams } from "react-router";
import { dynamicMEStore } from "store/Mapping/DMEStore";
import { PROPERTY_RULE_OPERATORS, SORT_OPTIONS } from "../../const";
import { useTranslation } from "react-i18next";
import { CustomFilterProps } from "components/Table/types";
import {
  IconEyeOff,
  IconFilter,
  IconFilterOff,
  IconLinkOff,
  IconSearch,
  IconSortAscending,
  IconSortDescending,
  IconSparkles,
} from "@tabler/icons-react";
import { CollectionIcon } from "@heroicons/react/outline";
import { GroupingRemoveIcon } from "../../utils";
import { Column, Row } from "@tanstack/react-table";
import { SingleItem } from "api-client";
import { searchStore } from "store/Mapping/SearchStore";
import useMappingEditor from "../../useMappingEditor";

const useTableMenus = (
  column: Column<unknown> | undefined,
  row?: Row<unknown> | undefined
) => {
  const { ifc_id } = useParams();
  const property = dynamicMEStore.findPropertyByKey(column?.id);
  const { t } = useTranslation();
  const { mappingToObjects } = useMappingEditor();

  const addFilterRule = async (value: string) => {
    if (!property) return;
    const rules = dynamicMEStore.selectedFilterByItems.rules;
    const suggestedValues = await dynamicMEStore.fetchColumnSuggestions(
      ifc_id,
      property
    );
    rules.push({
      property: property,
      operator: PROPERTY_RULE_OPERATORS[0].id,
      value: String(value),
      suggestedValues: suggestedValues ?? [],
    });
    dynamicMEStore.updateFilterRules(rules);
    !value && setTimeout(() => dynamicMEStore.setOpenFilter(true), 500);
  };

  const addGroupingRule = () => {
    if (!property) return;
    dynamicMEStore.setOpenGroupBy(true);
    dynamicMEStore.setSelectedGroupByItems([
      ...dynamicMEStore.selectedGroupByItems,
      property,
    ]);
    dynamicMEStore.reOrderGroupingColumns();
  };

  const removeGroupingRule = () => {
    property && dynamicMEStore.removeGroupingRule(property?.id);
  };

  const addSortingRule = (sort: string) => {
    if (!property) return;
    dynamicMEStore.setSelectedSortedByItems([
      {
        id: String(dynamicMEStore.selectedSortedByItems.length),
        property: property,
        sort: sort,
      },
    ]);
  };

  const removeColumn = () => {
    const filteredItems = dynamicMEStore.visibleColumnProperties.filter(
      (rec) => rec.column_key != column?.id
    );
    const propertyID = dynamicMEStore.findPropertyByKey(column?.id)?.id;
    propertyID && dynamicMEStore.removeItemFromSortList(propertyID);
    propertyID && dynamicMEStore.removeItemFromGoupingList(propertyID);
    dynamicMEStore.setVisibleColumnProperties(filteredItems);
  };

  const removeFilterRule = () => {
    const rules = dynamicMEStore.selectedFilterByItems.rules;
    const filteredItems = rules.filter(
      (rec) => rec.property?.column_key !== column?.id
    );
    dynamicMEStore.updateFilterRules(filteredItems);
  };

  const hasAnyFilterRuleForProperty = () => {
    return dynamicMEStore.selectedFilterByItems.rules.some(
      (rec) => rec.property?.column_key === column?.id
    );
  };

  const hasAnyGroupingRuleForProperty = () => {
    return dynamicMEStore.selectedGroupByItems.some(
      (rec) => rec?.column_key === column?.id
    );
  };

  const isByCellValueFiltered = (value: string) => {
    return dynamicMEStore.selectedFilterByItems.rules.some(
      (rec) => rec.property?.column_key === column?.id && rec.value === value
    );
  };

  function truncateText(text: string) {
    return text.length > 13 ? `${text.slice(0, 13)}...` : text;
  }

  const getPropertyName = () => {
    const text =
      (column?.columnDef as CustomFilterProps)?.label?.split(" (")[0] ?? "";
    return truncateText(text);
  };

  const getObjectName = () => {
    const text: string =
      (row?.original as never)?.["physical_object.component"] ?? "";
    return truncateText(text);
  };

  const getGroupObjectsCount = () => {
    return (row?.original as SingleItem)?.object_count ?? 0;
  };

  const propertyMenu = () => [
    {
      title: t("mappingEditor.property"),
      options: [
        {
          label: t("mappingEditor.addFilterForColumn", {
            propertyName: getPropertyName(),
          }),
          icon: <IconFilter width={19} strokeWidth={2.5} />,
          onClick: () => addFilterRule(""),
          hidden: hasAnyFilterRuleForProperty(),
        },
        {
          label: t("mappingEditor.removeFilterForColumn", {
            propertyName: getPropertyName(),
          }),
          icon: <IconFilterOff width={19} strokeWidth={2.5} />,
          onClick: () => removeFilterRule(),
          hidden: !hasAnyFilterRuleForProperty(),
        },
        {
          label: t("mappingEditor.groupByColumn", {
            propertyName: getPropertyName(),
          }),
          icon: <CollectionIcon width={19} strokeWidth={2.5} />,
          onClick: () => addGroupingRule(),
          hidden: hasAnyGroupingRuleForProperty(),
        },
        {
          label: t("mappingEditor.removeGroupByColumn", {
            propertyName: getPropertyName(),
          }),
          icon: <GroupingRemoveIcon />,
          onClick: () => removeGroupingRule(),
          hidden: !hasAnyGroupingRuleForProperty(),
        },
        {
          label: t("mappingEditor.sortByColumnAscending", {
            propertyName: getPropertyName(),
          }),
          icon: <IconSortAscending width={19} strokeWidth={2.5} />,
          onClick: () => addSortingRule(SORT_OPTIONS[0].id),
        },
        {
          label: t("mappingEditor.sortByColumnDescending", {
            propertyName: getPropertyName(),
          }),
          icon: <IconSortDescending width={19} strokeWidth={2.5} />,
          onClick: () => addSortingRule(SORT_OPTIONS[1].id),
        },
        {
          label: t("mappingEditor.hideColumnInTable", {
            propertyName: getPropertyName(),
          }),
          icon: <IconEyeOff width={19} strokeWidth={2.5} />,
          onClick: () => removeColumn(),
        },
      ],
    },
  ];

  const openProductSearchView = () => {
    searchStore.setOpenProductSearch(true);
  };

  const objectMenu = () => {
    return {
      title: t("mappingEditor.object"),
      options: [
        {
          label: t("mappingEditor.objectManualMapping", {
            objectName: getObjectName(),
          }),
          icon: <IconSearch width={19} strokeWidth={2.5} />,
          onClick: () => openProductSearchView(),
        },
        {
          label: t("mappingEditor.objectAutoMapping", {
            objectName: getObjectName(),
          }),
          icon: <IconSparkles width={19} strokeWidth={2.5} />,
          onClick: () =>
            mappingToObjects(
              "auto_map_match",
              null,
              row?.original as SingleItem
            ),
        },
        {
          label: t("mappingEditor.objectRemoveMapping", {
            objectName: getObjectName(),
          }),
          icon: <IconLinkOff width={19} strokeWidth={2.5} />,
          onClick: () =>
            mappingToObjects("undo_match", null, row?.original as SingleItem),
          hidden: (row?.original as SingleItem)?.product_match.id === null,
        },
      ],
    };
  };

  const groupMenu = () => {
    return {
      title: t("mappingEditor.groupTitle"),
      options: [
        {
          label: t("mappingEditor.groupManualMapping", {
            number_of_objects_of_group: getGroupObjectsCount(),
          }),
          icon: <IconSearch width={19} strokeWidth={2.5} />,
          onClick: () => openProductSearchView(),
        },
        {
          label: t("mappingEditor.groupAutoMapping", {
            number_of_objects_of_group: getGroupObjectsCount(),
          }),
          icon: <IconSparkles width={19} strokeWidth={2.5} />,
          onClick: () =>
            mappingToObjects(
              "auto_map_match",
              null,
              row?.original as SingleItem
            ),
        },
        {
          label: t("mappingEditor.groupRemoveMapping", {
            number_of_objects_of_group: getGroupObjectsCount(),
          }),
          icon: <IconLinkOff width={19} strokeWidth={2.5} />,
          onClick: () =>
            mappingToObjects("undo_match", null, row?.original as SingleItem),
          hidden: (row?.original as SingleItem)?.product_match.id === null,
        },
      ],
    };
  };

  return {
    addFilterRule,
    propertyMenu,
    isByCellValueFiltered,
    getPropertyName,
    truncateText,
    objectMenu,
    groupMenu,
  };
};

export default useTableMenus;
