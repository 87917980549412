import React from "react";

function EulePage() {
  return (
    <iframe
      src="https://app.eeule.de/login"
      title="text/html"
      width="100%"
      style={{
        border: "none",
        height: "88vh",
      }}
    ></iframe>
  );
}

export default EulePage;
