import React, { useEffect, useState } from "react";
import { ChevronUpIcon, UserAddIcon } from "@heroicons/react/outline";
import OrganisationSettingsMenu from "./OrganisationSettingsMenu";
import OrganisationUserManagement from "./OrganisationMembers";
import OrganisationAcccount from "./OrganisationAccountSettings";
import InviteMember from "features/Organisation/Settings/InviteMember";
import { userOrganisationsApi } from "api-client";
import { userRoles } from "../consts.s";
import { IS_CONCULAR_ONE, isTabletSize } from "utils";
import { AxiosResponse } from "axios";
import { useSetRecoilState } from "recoil";
import { sidebarOpenedAtom } from "store/atoms";
import { Organisation } from "../CollapseOrganisationsList";
import clsx from "clsx";
import { organisationStore } from "store/OrganisationStore";
import { observer } from "mobx-react-lite";
import { userStore } from "store/UserStore";

const OrganisationMenu = ({
  index,
  openOrganisation,
  updateOpenOrganisation,
  organisation,
}: {
  index: number;
  openOrganisation?: { id: string; status: boolean }[];
  updateOpenOrganisation?: (e: React.MouseEvent, index: number) => void;
  organisation: Organisation;
}) => {
  const { authHeader } = userStore;
  const setSidebarOpened = useSetRecoilState(sidebarOpenedAtom);
  const [openAddMember, setOpenAddMember] = useState(false);
  const [openSettings, setOpenSettings] = useState(false);
  const [openMembers, setOpenMembers] = useState(false);
  const isAdmin = organisation?.role === userRoles[0].id;
  const [multipleAdmin, setMultipleAdmin] = useState<boolean>(false);

  useEffect(() => {
    if (!isAdmin) return;
    (async () => await hasMultiAdmin())();
  }, [isAdmin]);

  async function hasMultiAdmin() {
    if (!authHeader) return;
    userOrganisationsApi
      .concularApiV1RoutersUserOrganisationsGetNumOfAdminsForOrganisation(
        organisation.id,
        authHeader
      )
      .then((response: AxiosResponse) => {
        if (response?.data?.no_of_admins > 1) {
          setMultipleAdmin(true);
        }
      })
      .catch((err) => console.log(err));
  }

  const onClickOrganisation = () =>
    organisationStore.setClickedOrganisation(organisation);

  const onOpenSettings = () => {
    setOpenSettings(true);
    if (isTabletSize()) {
      setSidebarOpened(false);
    }
  };
  const onOpenMembers = () => {
    setOpenMembers(true);
    if (isTabletSize()) {
      setSidebarOpened(false);
    }
  };

  function organisationSettingsIcons() {
    return (
      <div className="flex items-center">
        {isAdmin && (
          <UserAddIcon
            width={15}
            className="cursor-pointer mr-1 hover:text-black"
            onClick={() => {
              setOpenAddMember(true);
              if (isTabletSize()) {
                setSidebarOpened(false);
              }
            }}
          />
        )}
        <OrganisationSettingsMenu
          openMembers={onOpenMembers}
          openSettings={onOpenSettings}
          isAdmin={isAdmin}
          multipleAdmin={multipleAdmin}
        />
      </div>
    );
  }

  return (
    <>
      <div
        className="flex items-center text-gray-500 gap-1 relative"
        onClick={onClickOrganisation}
      >
        {!organisation.shared ? organisationSettingsIcons() : null}
        {!IS_CONCULAR_ONE && (
          <div
            className="py-1 hover:text-black transition"
            onClick={(e) => updateOpenOrganisation?.(e, index)}
          >
            <ChevronUpIcon
              width={15}
              className={clsx("cursor-pointer transition", {
                "rotate-90":
                  openOrganisation && !openOrganisation[index]?.status,
              })}
            />
          </div>
        )}
      </div>
      {openSettings && (
        <OrganisationAcccount
          open={openSettings}
          close={() => setOpenSettings(false)}
        />
      )}
      {openMembers && (
        <OrganisationUserManagement
          open={openMembers}
          close={() => setOpenMembers(false)}
        />
      )}
      {openAddMember && (
        <InviteMember
          open={openAddMember}
          close={() => setOpenAddMember(false)}
        />
      )}
    </>
  );
};

export default observer(OrganisationMenu);
