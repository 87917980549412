import React, { Fragment, ReactNode } from "react";
import { Combobox, Transition } from "@headlessui/react";
import useSwitch from "hooks/useSwitch";
import { useTranslation } from "react-i18next";
import useInvalidInput from "hooks/useInvalidInput";
import { slugify } from "utils";
import clsx from "clsx";
import NotFoundImg from "assets/images/icons/not-found.svg";
import { IconChevronDown, IconSearch, IconX } from "@tabler/icons-react";
import OptionItem from "./OptionItem";
import { dynamicMEStore } from "store/Mapping/DMEStore";

export interface DropdownOption {
  id?: string | number;
  name: string;
  name_de?: string;
  icon?: ReactNode;
  title?: string;
  title_de?: string;
  value?: string;
  children?: DropdownOption[];
}
export interface DropdownWithSearchProps<T>
  extends React.HTMLProps<HTMLInputElement> {
  labelName?: string;
  value?: string;
  items: T[];
  displayKey: keyof T;
  detailKey?: keyof T;
  handleChange?: (value: string) => void;
  handleSelect?: (value: T, name?: string) => void;
  className?: string;
  containerClassName?: string;
  optionsClassName?: string;
  handleRemoveValue?: () => void;
  name?: string;
  height?: "small" | "large" | "middle";
  noMargin?: boolean;
  labelWithSearch?: boolean;
  hideOptionsSearch?: boolean;
  searchClassName?: string;
  disabled?: boolean;
  onChangeSearchTerm?: (value: string | number) => void;
}

export default function DropdownWithSearch<Item extends object>(
  props: DropdownWithSearchProps<Item>
) {
  const [selectedItem, setSelectedItem] = React.useState<Item | null>(null);
  const [query, setQuery] = React.useState<string | number>("");
  const [showError, switchShowError] = useSwitch(false);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const optionsListRef = React.useRef<HTMLUListElement>(null);
  const [focusedIndex, setFocusedIndex] = React.useState<number>(-1);

  React.useEffect(() => {
    optionsListRef.current?.scrollTo({ top: 0 });
    setFocusedIndex(-1);
  }, [query]);

  React.useEffect(() => {
    setQuery(props.value ?? "");
  }, [props.value]);

  function checkQueryExists(item: Item, filteredList: Item[]) {
    let resolved;
    if (props.displayKey in item) {
      resolved = item[props.displayKey];
    } else return;
    const foundedItem = slugify(resolved as unknown as string).includes(
      slugify(query)
    );
    foundedItem && filteredList.push(item);
    if ((item as DropdownOption).children?.length) {
      (item as DropdownOption).children?.map((rec) =>
        checkQueryExists(rec as Item, filteredList)
      );
    }
  }

  function filterWithQuery() {
    const filteredList: Item[] = [];
    props.items.forEach((item) => {
      checkQueryExists(item, filteredList);
    });
    return filteredList;
  }

  const filteredItems: Item[] = query === "" ? props.items : filterWithQuery();

  useInvalidInput(inputRef, (isInvalid) => {
    if (isInvalid) {
      switchShowError.on();
    } else {
      switchShowError.off();
    }
  });

  function handleSearchKeyDown(e: React.KeyboardEvent) {
    e.stopPropagation();
    if (e.key === "Enter" || e.key === "Escape") {
      const shouldEnterQuery =
        focusedIndex === -1 || filteredItems.length === 0;
      shouldEnterQuery ? props.onChangeSearchTerm?.(query) : onOptionEnter();
    } else if (e.code === "ArrowDown") {
      e.preventDefault();
      focusToOptionsList();
      onArrowDown();
    } else if (e.code === "ArrowUp") {
      e.preventDefault();
      focusToOptionsList();
      onArrowUp();
    }
  }

  function onArrowDown() {
    setFocusedIndex((prev) =>
      prev === filteredItems.length - 1 ? prev : prev + 1
    );
  }

  function onArrowUp() {
    setFocusedIndex((prev) => (prev <= 0 ? -1 : Number(prev) - 1));
  }

  function onOptionEnter() {
    handleOnClick(filteredItems[focusedIndex]);
    dynamicMEStore.setOpenFilter(false);
  }

  function focusToOptionsList() {
    setTimeout(() => {
      optionsListRef.current?.focus();
      optionsListRef.current?.scrollTo({ top: 0 });
    }, 1);
  }

  function handleScrollList(e: React.KeyboardEvent) {
    e.stopPropagation();
    if (e.code === "ArrowDown") {
      onArrowDown();
    } else if (e.code === "ArrowUp") {
      onArrowUp();
      focusedIndex === -1 && inputRef.current?.focus();
    } else if (e.code === "Enter") {
      onOptionEnter();
    } else {
      inputRef.current?.focus();
    }
  }

  function searchField() {
    return (
      <Combobox.Input
        ref={inputRef}
        onSelect={(e: React.ChangeEvent<HTMLInputElement>) => {
          props.handleChange && props.handleChange(e.target.value);
        }}
        data-invalid={"false"}
        required={props.required}
        onInvalid={() => switchShowError.on()}
        name={props.name}
        className={clsx(
          "w-full pl-1 text-sm leading-5 ring-0 outline-none border-none focus:ring-0 rounded-md",
          props.searchClassName
        )}
        onChange={(event) => {
          switchShowError.off();
          setQuery(event.target.value);
        }}
        value={query}
        placeholder={props.placeholder}
        onKeyDown={handleSearchKeyDown}
      />
    );
  }

  const handleOnClick = (item: Item) => {
    props.handleSelect?.(item);
    setSelectedItem(item);
  };

  function emptyState() {
    return (
      <div className="flex justify-center">
        <div className="w-[300px] flex flex-col -z-10 py-4 items-center justify-center">
          <span>
            <img alt="not-found" src={NotFoundImg} className="pt-2" />
          </span>
          <span className="font-normal text-sm text-gray-500 p-4 text-center">
            {t("commons.onDropDownNoSearchResult")}
          </span>
        </div>
      </div>
    );
  }

  function removeValue() {
    props.handleRemoveValue?.();
    setQuery("");
  }

  const hasChildren = () => {
    return Boolean(
      filteredItems.find((item) => (item as unknown as DropdownOption).children)
    );
  };

  function optionListItems() {
    return (
      <Combobox.Options
        ref={optionsListRef}
        onKeyDown={handleScrollList}
        className={clsx(
          "absolute z-10 my-1 max-h-60 w-full overflow-auto rounded-md bg-white text-base !p-0 !max-w-fit",
          "shadow-lg border border-gray-300 min-w-auto w-auto max-w-md focus:outline-none sm:text-sm",
          props.optionsClassName,
          { "cursor-not-allowed": props.disabled }
        )}
      >
        {!props.labelWithSearch && !props.hideOptionsSearch && (
          <div className="sticky top-0 p-2 bg-white z-10">
            <div
              className={clsx(
                "bg-white mx-2 mt-1 border border-gray-300 rounded-md mb-2",
                "flex justify-between pl-2 items-center pr-3 text-gray-900"
              )}
            >
              {searchField()}
              <div className="flex items-center">
                <IconSearch width={18} className="text-gray-500" />
              </div>
            </div>
          </div>
        )}
        {filteredItems?.length === 0 && query !== ""
          ? emptyState()
          : filteredItems?.map((item, i) => (
              <OptionItem
                key={i}
                item={item}
                index={i}
                handleOnClick={handleOnClick}
                disabled={props.disabled}
                displayKey={props.displayKey}
                detailKey={props.detailKey}
                query={query}
                hasChildren={hasChildren()}
                focusedIndex={focusedIndex}
              />
            ))}
      </Combobox.Options>
    );
  }

  return (
    <div
      className={`${props.containerClassName ?? ""}${
        props.noMargin ? " mb-0" : " mb-4"
      }`}
    >
      <Combobox value={selectedItem} onChange={() => null}>
        <div className={clsx(props.className)}>
          <div
            className={clsx(
              "flex items-center cursor-pointer overflow-hidden rounded-md font-medium text-sm border-gray-300",
              "border text-left h-large",
              showError ? "border border-red-700 text-red-700" : "",
              {
                "h-large": props.height === "large",
                "!h-middle": props.height === "middle",
                "!h-small": props.height === "small",
              }
            )}
          >
            <Combobox.Button
              className={clsx(
                "flex justify-between w-full h-full inset-y-0 right-0 items-center px-2",
                "text-gray-700 bg-white"
              )}
            >
              {({ open }) => (
                <div className="flex justify-between w-full items-center">
                  <div className="max-w-[90%]">
                    {props.labelName && (
                      <label
                        className={clsx(
                          "cursor-pointer pr-1 flex items-center",
                          props.value ? "text-gray-700" : "text-gray-500"
                        )}
                        htmlFor={props.name as string}
                      >
                        {props.labelName}
                      </label>
                    )}
                    {props.labelWithSearch && searchField()}
                  </div>
                  {query ? (
                    <IconX
                      className="min-h-[17px] min-w-[17px] text-gray-500 hover:text-gray-800"
                      onClick={removeValue}
                    />
                  ) : (
                    <IconChevronDown
                      className={clsx(
                        "min-h-[17px] min-w-[17px] text-gray-500",
                        open ? "rotate-180" : ""
                      )}
                      aria-hidden="true"
                    />
                  )}
                </div>
              )}
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            {optionListItems()}
          </Transition>
        </div>
      </Combobox>
    </div>
  );
}
