import React, { useEffect, useState } from "react";
import { PlotParams } from "react-plotly.js";
// @ts-ignore
import Plotly from "plotly.js-concore";
import createPlotlyComponent from "react-plotly.js/factory";
const Plot = createPlotlyComponent(Plotly);

export interface BasePlotProps {
  data: Plotly.Data[];
  layout?: Partial<Plotly.Layout>;
  frames?: Plotly.Frame[] | undefined;
  config?: Partial<Plotly.Config> | undefined;
}

export const barChartColors = [
  "rgba(99, 102, 241, 1)", // #6366f1
  "rgba(165, 180, 252, 1)", // #a5b4fc
  "rgba(6, 182, 212, 1)", // #06b6d4
  "rgba(103, 232, 249, 1)", // #67e8f9
];

const baseLayout: Partial<Plotly.Layout> | object = {
  autosize: true,
  paper_bgcolor: "rgba(0,0,0,0)",
  plot_bgcolor: "rgba(0,0,0,0)",
  margin: {
    l: 10,
    r: 10,
    b: 0,
    t: 0,
  },
  title: null,
  barmode: "relative",
  legend: {
    orientation: "h",
  },
};

export function basePlotlyConfig(
  layout: Partial<Plotly.Layout> | object | undefined
): BasePlotProps {
  const plotLayout = { ...baseLayout, ...(layout || {}) };

  const plotBaseConfig: BasePlotProps = {
    data: [],
    layout: plotLayout,
    frames: [],
    config: {
      displaylogo: false,
      showTips: true,
      responsive: true,
      displayModeBar: false,
    },
  };
  return plotBaseConfig;
}

export default function BasePlot(props: BasePlotProps) {
  const [state, setState] = useState<PlotParams>({
    data: props.data,
    layout: props.layout || {},
    frames: props.frames || [],
    config: props.config || {},
  });

  useEffect(() => {
    if (props.data === state.data) return;
    setState((previous) => {
      return { ...previous, data: props.data };
    });
  }, [props.data]);

  return (
    <div className="w-full h-full flex justify-start">
      <div className="print:hidden print:h-0 w-full h-full">
        <Plot
          data={state.data}
          layout={state.layout}
          frames={state.frames}
          config={state.config}
          onInitialized={(figure) => setState(figure as PlotParams)}
          onUpdate={(figure) => setState(figure as PlotParams)}
          className="w-full h-full"
        />
      </div>
      <div className="hidden print:block">
        <Plot
          data={state.data}
          layout={state.layout}
          frames={state.frames}
          config={state.config}
          onInitialized={(figure) => setState(figure as PlotParams)}
          onUpdate={(figure) => setState(figure as PlotParams)}
          className="w-700 h-auto"
        />
      </div>
    </div>
  );
}
