import React, { ReactNode } from "react";
import Header from "layouts/Header";
import clsx from "clsx";
import Sidebar from "layouts/Sidebar";
import { useRecoilState } from "recoil";
import { sidebarOpenedAtom } from "store/atoms";
import Toast from "components/Toast";
import { globalStore } from "store/GlobalStore";
import { observer } from "mobx-react-lite";
import { IS_CONCULAR_ONE } from "utils";
interface IProps {
  children?: ReactNode;
  className?: string;
  headerTitle?: string;
  noHeader?: boolean;
}

const MainLayout = ({ children, className, headerTitle, noHeader }: IProps) => {
  const [sidebarOpened, setSidebarOpened] = useRecoilState(sidebarOpenedAtom);
  const handleSidebar = () => setSidebarOpened(!sidebarOpened);
  const { toastList } = globalStore;

  return (
    <>
      <Sidebar open={sidebarOpened} toggleSidebar={handleSidebar} />
      <div
        className={clsx("transition-all", { "md:ml-[300px]": sidebarOpened })}
      >
        {noHeader ? null : (
          <Header
            headerTitle={headerTitle}
            sidebarOpen={sidebarOpened}
            toggleSidebar={handleSidebar}
          />
        )}
        <div
          className={clsx("min-h-[calc(100vh-48px)] bg-gray-50", className, {
            "min-h-[100vh]": IS_CONCULAR_ONE,
          })}
        >
          {children}
        </div>
        {toastList && <Toast toastList={toastList} />}
      </div>
    </>
  );
};

export default observer(MainLayout);
