import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AddEditBuilding from "../AddEditBuilding";
import { PlusIcon } from "@heroicons/react/solid";
import { BuildingOUT, OrganisationOUT } from "api-client";
import { isTabletSize } from "utils";
import Button from "components/Button";
import { Organisation } from "features/Organisation/CollapseOrganisationsList";
import SortedBuildingsList from "./SortedBuildingsList";
import { useSetRecoilState } from "recoil";
import { sidebarOpenedAtom } from "store/atoms";
import { useNavigate } from "react-router";
import { buildingStore } from "store/Building/BuildingStore";
import { observer } from "mobx-react-lite";
import { organisationStore } from "store/OrganisationStore";
import { OrganisationBuilding } from "store/Building/types";
import MaxBuildingCountAlert from "./MaxBuildingCountAlert";

const BuildingsList = observer(
  ({ organisation }: { organisation: OrganisationOUT }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { organisationsBuildings } = buildingStore;

    const [showAddBuilding, setShowAddBuilding] = useState(false);
    const [buildings, setBuildings] = useState<OrganisationBuilding>({
      loading: true,
      items: [],
    });

    const [isMaxLimit, setShowMaxLimitModal] = useState(false);
    const [sampleBuildingStatus, setSampleBuildingStatus] = useState<
      string | null
    >(null);
    const maxBuildingNo = organisation.max_building_entry ?? 0;
    const createdBuildings =
      organisationsBuildings.find((b) => b.orgId === organisation.id)?.items
        .length ?? 0;

    const setSidebarOpened = useSetRecoilState(sidebarOpenedAtom);

    const showBuildingDetail = (building: BuildingOUT) => {
      if (buildingStore.currentBuilding?.id !== building.id) {
        buildingStore.setCurrentBuilding(building);
      }
      navigate(`/buildings/${building.id}`);
      if (isTabletSize()) {
        setSidebarOpened(false);
      }
    };

    const handleAddBuilding = (organisation: Organisation) => {
      if (createdBuildings >= maxBuildingNo) {
        setShowMaxLimitModal(true);
        return;
      }
      organisationStore.setCurrentOrganisation(organisation);
      setShowAddBuilding(true);
      if (isTabletSize()) {
        setSidebarOpened(false);
      }
    };

    const handleAddDemoBuilding = async (organisation: Organisation) => {
      if (!organisation) return;
      setSampleBuildingStatus("in_progress");
      await organisationStore.addDemoBuilding(organisation.id);
      setSampleBuildingStatus("done");
    };

    const addMoreBuildings = () => {
      return (
        <div className="mt-3">
          <Button
            trailingIcon={<PlusIcon width={15} />}
            width="fit-content"
            type="gray"
            size="small"
            onClick={() => handleAddBuilding(organisation)}
            testID={`addBuilding_${organisation.id}_button`}
          >
            {t("dashboard.addBuilding")}
          </Button>
        </div>
      );
    };

    function onCloseModal() {
      setShowMaxLimitModal(false);
      setSampleBuildingStatus(null);
    }

    useEffect(() => {
      const currentOrgBuildings = organisationsBuildings.find(
        (rec) => rec.orgId === organisation.id
      );
      if (!currentOrgBuildings) return;
      setBuildings({
        loading: currentOrgBuildings.loading,
        items: currentOrgBuildings.items,
      });
      setSampleBuildingStatus(null);
    }, [organisation, organisationsBuildings]);

    return (
      <>
        <div className="pt-3">
          {buildings.loading ? (
            skeleton()
          ) : (
            <SortedBuildingsList
              buildings={buildings.items}
              showDetail={showBuildingDetail}
            />
          )}
          {addMoreBuildings()}
        </div>
        {showAddBuilding && (
          <AddEditBuilding
            onMaxLimitWarning={setShowMaxLimitModal}
            open={showAddBuilding}
            close={() => setShowAddBuilding(false)}
            type="add"
          />
        )}
        <MaxBuildingCountAlert
          open={isMaxLimit}
          close={onCloseModal}
          sampleBuildingStatus={sampleBuildingStatus}
          onAddSampleBuilding={() => handleAddDemoBuilding(organisation)}
          maxBuildingNo={maxBuildingNo}
        />
      </>
    );
  }
);

export default BuildingsList;

function skeleton() {
  return (
    <div className="animate-pulse pl-1 mt-2">
      <div className="w-full flex mb-4 items-center">
        <div className="w-7 h-7 bg-gray-200 rounded dark:bg-gray-700"></div>
        <div className="w-[80%] h-5 bg-gray-200 rounded dark:bg-gray-700 ml-2"></div>
      </div>
    </div>
  );
}
